export const IS_LOADING = "IS_LOADING";
export const IS_LOADED = "IS_LOADED";
export const SHOW_ERROR = "SHOW_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SHOW_USERID = "SHOW_USERID";

export const AUTH_USER = "AUTH_USER";
export const REGISTER_USER = "REGISTER_USER";
export const SCAN_CODE = "SCAN_CODE";
export const ADD_USERINFO = "ADD_USERINFO";
export const GET_REWARD = "GET_REWARD";
export const GET_NEWS = "GET_NEWS";
export const GET_ARTICLE = "GET_ARTICLE";
export const GET_CONTEST = "GET_CONTEST";
export const GET_BLOG = "GET_BLOG";
export const GET_QUEST = "GET_QUEST";
export const GET_JOURNEY = "GET_JOURNEY";
export const GET_WISHINGTREE = "GET_WISHINGTREE";
export const GET_VOUCHER = "GET_VOUCHER";
export const GET_DONATE = "GET_DONATE";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const ADD_BABYINFO = "ADD_BABYINFO";
export const EDIT_BABYINFO = "EDIT_BABYINFO";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const EDIT_CART = "EDIT_CART";
export const SEND_ORDER = "SEND_ORDER";
export const TRACK_ORDER = "TRACK_ORDER";
export const POINT_HISTORY = "POINT_HISTORY";
export const GET_INBOX = "GET_INBOX";
export const GET_SAMPLE = "GET_SAMPLE";
export const REMOVE_INBOX = "REMOVE_INBOX";
export const READ_MESSAGE = "READ_MESSAGE";
export const REDEEM_VOUCHER = "REDEEM_VOUCHER";
export const DONATE_POINTS = "DONATE_POINTS";
export const DONATE_WISHER = "DONATE_WISHER";
export const ADD_FEEDBACK = "ADD_FEEDBACK";
export const GET_QUESTION = "GET_QUESTION";
export const GET_BRAND_INFO = "GET_BRAND_INFO";
export const FETCH_SAMPLE = "FETCH_SAMPLE";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const SELECT_LANG = "SELECT_LANG";
export const ALREADY_LOGIN = "ALREADY_LOGIN";
export const JUST_LOGIN = "JUST_LOGIN";
export const FORCE_EDIT = "FORCE_EDIT";
export const FORCE_ADDRESS = "FORCE_ADDRESS";
export const FORCE_BABYINFO = "FORCE_BABYINFO";
export const GET_PREGNANT = "GET_PREGNANT";
export const SEND_PREGNANT = "SEND_PREGNANT";
export const SET_MODAL_SEEN = "SET_MODAL_SEEN";
export const SSO_POPUP = "SSO_POPUP";

export const AUTH_ADMIN = "AUTH_ADMIN";
export const LOG_OUT = "LOG_OUT";
