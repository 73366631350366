import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
//Redux
import { connect } from "react-redux";
import { getNews } from "../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

function News({ news, getNews, language, user }) {
	const { t } = useTranslation();
	useEffect(() => {
		if (!news) getNews();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="dashboardNewsStore">
			<h1 className="dashboardNewsTitle">{t("latestpromo")}</h1>

			<div>
				<Slider autoplay={true} autoplaySpeed={6000} easing="ease-in" dots={true}>
				{news && news.length ? (
				news
					.filter((x) => x.active && x.active === true)
					.filter((x) => {
						if (user.pregnancyStage === "pregnant") {
							return x.showingTo.includes("All") || x.showingTo.includes("pregnant");
						} else if (user.pregnancyStage === "trying") {
							return x.showingTo.includes("All") || x.showingTo.includes("trying");
						} else if (user.pregnancyStage === "oneChild") {
							return x.showingTo.includes("All") || x.showingTo.includes("oneChild");
						} else if (user.pregnancyStage === "moreChild") {
							return x.showingTo.includes("All") || x.showingTo.includes("moreChild");
						}
						return false;
						})
					.map((x) =>
						!x.link ? (
					<Link
						style={{ textDecoration: "none" }}
						to={"/viewnews?news=" + x.id} // Use x.link if it exists, otherwise use "/viewnews?news=" + x.id
						key={x.id}
					>
						<div className="dashboardNewsBox">
						<img src={x.image[language]} alt={x.title} className="dashboardNewsImage" />
						</div>
					</Link> ) :
					(
						<a href={x.link} target="_blank" key={x.id}>
						<div className="dashboardNewsBox">
						  <img src={x.image[language]} alt={x.title} className="dashboardNewsImage" />
						</div>
					  </a>
					)
					)
				) : (
				<></>
				)}


					{/* <div className="dashboardNewsBox">
						<img src={news[0].image} alt="" className="dashboardNewsImage"></img>
					</div>

					<div className="dashboardNewsBox">
						<img src={news[0].image} alt="" className="dashboardNewsImage"></img>
					</div> */}
				</Slider>
			</div>
		</div>
	);
}

const mapDispatchToProps = dispatch => {
	return {
		getNews: data => dispatch(getNews(data))
	};
};

const mapStateToProps = state => {
	return {
		news: state.userReducer.news,
		language: state.pageReducer.language,
		user: state.userReducer.user,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
