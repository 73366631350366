import { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import { IS_LOADING, IS_LOADED } from "../../../../redux/types";
import { codeDetailsColumns } from "../../../../config/mdbColumns";
import { CSVLink } from "react-csv";

const CodeDetails = ({ history, location, loading, loaded }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		if (!location.state) {
			history.push("/admin/scancode/dashboard");
		} else {
			const id = location.state;
			loading();
			axios
				.get(`/api/admin/fetch/dashboard/code/batch/details?id=${id}`)
				.then(res => {
					console.log(res.data);
					setData(res.data);
					loaded();
				})
				.catch(err => {
					alert(err.response.data.error);
					loaded();
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableData = useMemo(() => {
		return {
			columns: codeDetailsColumns,
			rows: data
		};
	}, [data]);

	const downloadData = useMemo(() => {
		return {
			headers: [
				{
					label: "Code",
					key: "code"
				}
			],
			data: data.map(x => ({ code: x.code }))
		};
	}, [data]);

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Code Details</h3>

					<div className="mt-5 card">
						<div className="card-header">
							<h5 className="card-title">Codes</h5>
						</div>

						<div className="card-body text-center">
							<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
						</div>

						<div className="card-footer">
							<CSVLink
								headers={downloadData.headers}
								data={downloadData.data}
								filename={`${location.details.brand}(${location.details.format.toUpperCase()})size-${location.details.size.toUpperCase()}.csv`}
								className="btn btn-primary"
								target="_blank"
							>
								Download Codes as CSV
							</CSVLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(CodeDetails);
