import { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import FacebookLogin from "@greatsumini/react-facebook-login";
import GoogleLogin from "react-google-login";

import { AiFillFacebook, AiFillGoogleCircle } from "react-icons/ai";

import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import Loader from "../../../component/Loader";
import InteractiveBox from "../../../component/landingPage/InteractiveBox";
import ContactUs from "../../../component/landingPage/ContactUs";
import { getSample, registerUser, clearError, selectLanguage, verifyUser } from "../../../redux/actions/userActions";

// import { states, postcodes, cities } from "../../../config/dataStandardize";
// import areaData from "../../../config/areaData.json";

// import packshot from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/landingPage/landingPackshot.png";

import { useTranslation } from "react-i18next";

const LandingPage = ({
	history,
	getSample,
	sample,
	registerUser,
	verifyUser,
	pageReducer,
	clearError,
	match,
	selectLanguage,
	currentLanguage,
	location
}) => {
	const { t } = useTranslation();
	// const [states, setStates] = useState([]);
	const [postcodes, setPostcodes] = useState([]);
	const [cities, setCities] = useState([]);
	const [dropdown, setDropdown] = useState({
		states: [],
		postcodes: [],
		cities: []
	});
	const [areaData, setAreaData] = useState([]);
	const [redeemSample, setRedeem] = useState(false);
	const [selected, setSelected] = useState({
		size: "nb"
		// format: ""
	});
	const [sampleId, setSampleId] = useState("");
	// const [formatNames, setFormatNames] = useState([]);
	const [data, setData] = useState({
		name: "",
		number: "",
		email: "",
		password: "",
		dob: false,
		edb: false,
		gender: "",
		race: "",
		line1: "",
		line2: "",
		line3: "",
		city: "",
		state: "",
		postcode: "",
		country: "Malaysia",
		pregnancyStage: "",
		referCode: "",
		receiveMail: true,
		childDob: null,
		// childGender: "",
		utmSource: "",
		utmMedium: "",
		utmCampaign: ""
	});
	const [childCount, setChildCount] = useState(1);
	// const [dropdown, setDropdown] = useState({
	// 	states: states.sort((a, b) => {
	// 		if (a.label > b.label) return 1;
	// 		if (a.label < b.label) return -1;
	// 		return 0;
	// 	}),
	// 	postcodes,
	// 	cities
	// });
	const [inputError, setInputError] = useState({
		name: null,
		number: null,
		email: null
	});
	const today = new Date();
	const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

	const [otp, setOtp] = useState("");
	const [showOtp, setShowOtp] = useState(false);
	const [uuid, setUuid] = useState(null);
	const [passReferCode, setReferCode] = useState(null);
	const [orderId, setOrderId] = useState(null);
	const [passwordStrength, setPasswordStrength] = useState(0); // 0: Very Weak, 1: Weak, 2: Good, 3: Strong

	//timer for OTP button
	const [timerStart, setTimerStart] = useState(false);
	const [timerDuration, setTimerDuration] = useState(60);

	const [ssoModal, setSsoModal] = useState(false);

	const closeSso = () => setSsoModal(false);

	useEffect(() => {
		// Check if the 'ssoPopup' key exists in localStorage
		const hasSeenSsoModal = localStorage.getItem("ssoPopup");

		// If 'ssoPopup' is not set in localStorage, show the modal
		if (!hasSeenSsoModal) {
			setSsoModal(true);

			// Set 'ssoPopup' in localStorage to track that the user has seen the modal
			localStorage.setItem("ssoPopup", "true");
		}
	}, []);

	let childNumbers = {
		1: t("firstChild"),
		2: t("secondChild"),
		3: t("thirdChild"),
		4: t("fourthChild"),
		5: t("fifthChild"),
		6: t("sixthChild"),
		7: t("seventhChild"),
		8: t("eightChild"),
		9: t("ninthChild"),
		10: t("tenthChild")
	};
	const [sampleName, setSampleName] = useState("");
	// const [sampleSizeId, setSampleSizeId] = useState("");

	// const [notification, setNotification] = useState(true);

	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// 	setTimerStart(true);
	// }, []);

	function generateUniqueToken(length) {
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		const tokenArray = [];

		for (let i = 0; i < length; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length);
			tokenArray.push(characters.charAt(randomIndex));
		}

		return tokenArray.join("");
	}

	// Example usage: Generate a unique token of length 16
	const uniqueToken = generateUniqueToken(16);

	const [otpModal, setShow] = useState(false);
	const handleClose = () => setShow(false);

	const showOTPModal = () => {
		setShow(true);
	};

	useEffect(() => {
		//1000 ms = 1 s
		//If (timerStart), timer will start
		let timer = null;
		if (timerStart) timer = setInterval(() => setTimerDuration(timerDuration - 1), 1000);
		if (timerDuration === 0) setTimerStart(false);
		if (timerStart === true && timerDuration === 0) setTimerDuration(60);

		return () => {
			clearInterval(timer);
			timer = null;
		};
	}, [timerStart, timerDuration]);

	useEffect(() => {
		axios
			.get("https://mystatic123.s3.ap-southeast-1.amazonaws.com/areaData.json")
			.then(res => {
				setAreaData(res.data);
			})
			.catch(console.error);
	}, []);

	useEffect(() => {
		axios
			.get("https://mystatic123.s3.ap-southeast-1.amazonaws.com/dataStandardize.json")
			.then(res => {
				// console.log(res.data);
				setDropdown({
					states: res.data.states.sort((a, b) => {
						if (a.label > b.label) return 1;
						if (a.label < b.label) return -1;
						return 0;
					}),
					postcodes: res.data.postcodes,
					cities: res.data.cities
				});
				// setStates(res.data.states);
				setPostcodes(res.data.postcodes);
				setCities(res.data.cities);
			})
			.catch(console.error);
	}, []);

	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// 	setTimerStart(true);
	// }, []);

	useEffect(() => {
		//1000 ms = 1 s
		//If (timerStart), timer will start
		let timer = null;
		if (timerStart) timer = setInterval(() => setTimerDuration(timerDuration - 1), 1000);
		if (timerDuration === 0) setTimerStart(false);
		if (timerStart === true && timerDuration === 0) setTimerDuration(60);

		return () => {
			clearInterval(timer);
			timer = null;
		};
	}, [timerStart, timerDuration]);


	useEffect(() => {
		if (match.params.language !== "en" && match.params.language !== "bm") {
			history.push(`/${pageReducer.language}`);
		} else {
			if (pageReducer.language !== match.params.language) selectLanguage(match.params.language);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (isMounted && !sample) getSample();
		return () => (isMounted = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (sample && selected.size && isMounted) {
			const firstItem = sample.data.filter(item => item.size === selected.size && item.active)[0];
			if (firstItem) {
				isMounted && setSampleId(firstItem.id);
			} else {
				isMounted && setSampleId("");
			}
		}

		return () => (isMounted = false);
	}, [selected, sample]);

	useEffect(() => {
		// when user changed state
		let isMounted = true;
		if (data.state && isMounted) {
			const sortAlpha = (a, b) => {
				if (a.label > b.label) return 1;
				if (a.label < b.label) return -1;
				return 0;
			};
			const thisCities = areaData
				.filter(x => x.state === data.state)
				.map(x => x.city)
				.filter((x, y, z) => z.indexOf(x) === y);
			const filteredCities = cities.filter(x => thisCities.includes(x.value)).sort(sortAlpha);

			if (filteredCities.length === 1) {
				isMounted &&
					setData({
						...data,
						city: filteredCities[0].value,
						postcode: ""
					});
			} else {
				isMounted &&
					setData({
						...data,
						city: "",
						postcode: ""
					});
			}
			isMounted &&
				setDropdown({
					...dropdown,
					cities: filteredCities
				});
		}

		return () => (isMounted = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.state]);

	useEffect(() => {
		// when user changed city
		let isMounted = true;
		if (data.city && isMounted) {
			const thisPostcodes = areaData
				.filter(x => x.state === data.state && x.city === data.city)
				.map(x => x.postcode)
				.filter((x, y, z) => z.indexOf(x) === y);
			const filteredPostcodes = postcodes.filter(x => thisPostcodes.includes(x.value));

			if (filteredPostcodes.length === 1) {
				isMounted &&
					setData({
						...data,
						postcode: filteredPostcodes[0].value
					});
			} else {
				isMounted &&
					setData({
						...data,
						postcode: ""
					});
			}
			isMounted &&
				setDropdown({
					...dropdown,
					postcodes: filteredPostcodes
				});
		}
		return () => (isMounted = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.city]);

	const selectedStyle = (type, val) => {
		if (val === selected[type]) {
			return {
				backgroundColor: "white",
				color: "#223C96"
			};
		} else {
			return {};
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (sampleId && sample && isMounted) {
			const foundData = sample.data.find(data => data.id === sampleId);
			if (foundData) {
				setSampleName(foundData.itemName.en);
				// setSampleSizeId(foundData.sizeId);
			} else {
				setSampleName("");
				// setSampleSizeId("");
			}
		}

		return () => (isMounted = false);
	}, [sampleId, sample]);

	const handleResendOTP = () => {
		console.log("resend otp");
		axios
			.post("/api/user/otp/resend", { number: data.number })
			.then(res => {
				console.log(res.data.message);
			})
			.catch(err => {
				console.log(err);
			});
		setTimerStart(true);
	};

	const handleFBLogin = user => {
		console.log("handle fb login");
		const { birthday, email, gender, name } = user;
		setData({
			...data,
			name: name || "",
			gender: gender === "male" || gender === "female" ? gender : "",
			email: email || "",
			dob: birthday
				? new Date(
						parseInt(birthday.split("/")[2]),
						parseInt(birthday.split("/")[0]) - 1,
						parseInt(birthday.split("/")[1])
				  )
				: new Date()
		});
	};

	const handleFBProfile = user => {
		console.log("handle fb profile");
		const { birthday, email, gender, name } = user;
		setData({
			...data,
			name: name || "",
			gender: gender === "male" || gender === "female" ? gender : "",
			email: email || "",
			dob: birthday
				? new Date(
						parseInt(birthday.split("/")[2]),
						parseInt(birthday.split("/")[0]) - 1,
						parseInt(birthday.split("/")[1])
				  )
				: new Date()
		});
	};

	const handleGoogleLogin = user => {
		const { googleId, accessToken, profileObj } = user;
		const { name, email } = profileObj;
		axios
			.get(
				`https://people.googleapis.com/v1/people/${googleId}?personFields=birthdays,genders&access_token=${accessToken}`
			)
			.then(res => {
				if (res.data) {
					let gender = "";

					if (res.data && res.data.genders && res.data.genders[0] && res.data.genders[0].value) {
						if (res.data.genders[0].value === "male" || res.data.genders[0].value === "female") {
							gender = res.data.genders[0].value;
						}
					}

					let dob = new Date();
					if (res.data && res.data.birthdays && res.data.birthdays[0] && res.data.birthdays[0].date) {
						dob = new Date(
							res.data.birthdays[0].date.year,
							res.data.birthdays[0].date.month - 1,
							res.data.birthdays[0].date.day
						);
					}

					setData({
						...data,
						name: name || "",
						email: email || "",
						gender,
						dob
					});
				}
			})
			.catch(console.error);
	};

	const handleLoginFailure = err => {
		console.error(err);
	};

	const handleChange = e => {
		setData({ ...data, [e.target.name]: e.target.value });
		if (e.target.name === "name") {
			const nameRegex = /^[a-zA-Z ]*$/;
			if (!nameRegex.test(e.target.value)) {
				setInputError({
					...inputError,
					name: t("nameerror")
				});
			} else {
				setInputError({
					...inputError,
					name: null
				});
			}
		} else if (e.target.name === "number") {
			const numberRegex = /^(01)[0-46-9]*[0-9]{8,9}$/;
			if (!numberRegex.test(e.target.value)) {
				setInputError({
					...inputError,
					number: t("numbererror")
				});
			} else {
				setInputError({
					...inputError,
					number: null
				});
			}
		} else if (e.target.name === "email") {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!emailRegex.test(e.target.value)) {
				setInputError({
					...inputError,
					email: t("emailerror")
				});
			} else {
				setInputError({
					...inputError,
					email: null
				});
			}
		} else if (e.target.name === "password") {
			const strength = getPasswordStrength(e.target.value);
			setPasswordStrength(strength);
		}
	};

	const getPasswordStrength = password => {
		const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

		if (password.length < 6) {
			return 0; // Very Weak
		} else if (password.length < 10) {
			return 1; // Weak
		} else if (strongRegex.test(password)) {
			return 3; // Strong
		} else {
			return 2; // Good
		}
	};

	const getPasswordStrengthColor = () => {
		switch (passwordStrength) {
			case 0:
				return "red"; // Very Weak
			case 1:
				return "orange"; // Weak
			case 2:
				return "yellow"; // Good
			case 3:
				return "green"; // Strong
			default:
				return "transparent";
		}
	};

	const getPasswordStrengthText = strength => {
		switch (strength) {
			case 0:
				return t("ssoPasswordValidation");
			case 1:
				return t("ssoPasswordValidation");
			case 2:
				return t("ssoPasswordValidation");
			case 3:
				return "";
		}
	};

	const handleAddChild = () => {
		setChildCount(childCount + 1);
		setData({ ...data, [`childDob${childCount}`]: null });
	};

	// const goToLinks = val => {
	// 	if (val === "privacypolicy")
	// 		return (window.location.href = `https://www.drypers.com.my/${currentLanguage}/privacy-policy/#privacy-policy`);
	// 	else if (val === "termsofuse") return (window.location.href = `https://www.drypers.com.my/${currentLanguage}/terms-conditions/`);
	// 	else console.log("no link");
	// };

	const handleChoose = (type, val) => setSelected({ ...selected, [type]: val });

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const utmSource = urlParams.get("utm_source") || "";
		const utmMedium = urlParams.get("utm_medium") || "";
		const utmCampaign = urlParams.get("utm_campaign") || "";
		setData({
			...data,
			utmSource,
			utmMedium,
			utmCampaign
		});
	}, []);

	const handleSubmit = e => {
		e.preventDefault();
		const {
			name,
			number,
			email,
			password,
			dob,
			edb,
			gender,
			race,
			line1,
			line2,
			line3,
			city,
			state,
			postcode,
			country,
			pregnancyStage,
			referCode,
			receiveMail,
			// ...childData
			utmSource,
			utmMedium,
			utmCampaign
		} = data;

		const children = [];

		for (let i = 0; i < childCount; i++) {
			const childDob = data[`childDob${i}`] ? new Date(data[`childDob${i}`]).toISOString() : null;
			// const childGender = data[`childGender${i}`];

			// Check if pregnancyStage is "trying" or "pregnant"
			if ((pregnancyStage === "trying" || pregnancyStage === "pregnant") && !childDob) {
				// Do nothing if trying or pregnant and child's information is not provided
			} else {
				children.push({ childDob });
			}
		}

		const nameRegex = /^[a-zA-Z ]*$/;
		const numberRegex = /^(01)[0-46-9]*[0-9]{8,9}$/;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const postcodeRegex = /^\d+$/;

		if (!showOtp) {
			if (redeemSample) {
				// if got redeem sample
				if (
					name &&
					number &&
					email &&
					password &&
					dob &&
					// edb &&
					gender &&
					race &&
					line1 &&
					city &&
					state &&
					postcode &&
					country &&
					pregnancyStage &&
					nameRegex.test(name) &&
					numberRegex.test(number) &&
					emailRegex.test(email) &&
					postcodeRegex.test(postcode) &&
					sampleId &&
					children
				) {
					const address = {
						line1,
						line2,
						line3,
						city,
						state,
						postcode,
						country
					};
					const sendThis = {
						name,
						number,
						email: email.toLowerCase(),
						dob,
						edb: edb || null,
						gender,
						race,
						address,
						pregnancyStage,
						referCode,
						password,
						redeemSample,
						sampleId,
						nationality: "malaysian",
						receiveMail,
						language: currentLanguage || "en",
						children,
						utmSource: utmSource || null,
						utmMedium: utmMedium || null,
						utmCampaign: utmCampaign || null
					};
					registerUser(sendThis, (uuid, referCode, orderId) => {
						setUuid(uuid);
						setReferCode(referCode);
						setOrderId(orderId);
						setShowOtp(true);
						setTimerStart(true);
						showOTPModal();
					});
				}
			} else {
				// no redeem sample
				if (
					name &&
					number &&
					email &&
					password &&
					dob &&
					// edb &&
					gender &&
					race &&
					pregnancyStage &&
					nameRegex.test(name) &&
					numberRegex.test(number) &&
					emailRegex.test(email) &&
					children
				) {
					const sendThis = {
						name,
						number,
						email: email.toLowerCase(),
						dob,
						edb: edb || null,
						gender,
						race,
						pregnancyStage,
						referCode,
						password,
						redeemSample,
						nationality: "malaysian",
						receiveMail,
						language: currentLanguage || "en",
						children,
						utmSource: utmSource || null,
						utmMedium: utmMedium || null,
						utmCampaign: utmCampaign || null
					};
					registerUser(sendThis, (uuid, referCode, orderId) => {
						setUuid(uuid);
						setReferCode(referCode);
						setOrderId(orderId);
						setShowOtp(true);
						setTimerStart(true);
						showOTPModal();
					});
				}
			}
		} else {
			if (otp) {
				const sendThis = {
					otp,
					number,
					id: uuid,
					uniqueToken
				};
				verifyUser(sendThis, () => {
					history.push({
						pathname: `/${currentLanguage}/registration/success`,
						success: true,
						userObj: {
							name,
							number,
							email,
							dob,
							gender,
							race,
							pregnancyStage,
							uuid: sendThis.id,
							referCode: passReferCode,
							edb,
							children,
							sampleName,
							sampleSizeId: selected.size,
							orderId,
							uniqueToken
						}
					});
				});
			}
		}
	};

	return (
		<div id="landing-page">
			<Header history={history} match={match} location={location} />

			<div className="content">
				<img
					src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/landingPage/headerImg.png"
					alt="header"
					className="img-fluid"
					style={{ width: "100%", height: "auto" }}
				/>

				{/* <h6 className="join-button" onClick={() => history.push(`/${pageReducer.language}/signin`)}>
					{t("joinFitAThon")}
				</h6> */}

				<section id="landing-info-section">
					<div className="info-section">
						<h1>{t("dbccap")}</h1>
						<h3>{t("firstcuddle")}</h3>
						<p className="mt-4">{t("babyfirstcry")}</p>
						{/* {pageReducer.language === "en" ? (
							<a href="#FreeSamples">
								<img
									src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/landingPage/signupButton.png"
									alt="signupButton"
									className="img-fluid signup-button"
								/>
							</a>
						) : (
							<a href="#FreeSamples">
								<img
									src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/landingPage/signupButtonBM.png"
									alt="signupButton"
									className="img-fluid signup-button"
								/>
							</a>
						)} */}
					</div>

					<section id="FreeSamples" className="auth-section">
						{/* <div style={{ padding: "0 5vw 80px", color: "white", textAlign: "justify", maxWidth: "1000px", margin: "auto" }}>
						<h5 style={{ whiteSpace: "pre-line", margin: "auto", lineHeight: "1.5em" }}>{t("comingSoon")}</h5>
					</div> */}
						<div className="text-center">
							<Loader
								component={
									<div id="register-sample-container">
										<div className="px-4 text-center">
											<div id="redeem-sample-question">
												<p>{t("wouldyoulike")}</p>
												<div id="redeem-sample-choice">
													<button
														type="button"
														id="yes"
														className={`redeem-sample-button ${redeemSample && "active"}`}
														onClick={() => setRedeem(true)}
													>
														{t("yes")}
													</button>
													<button
														type="button"
														id="no"
														className={`redeem-sample-button ${!redeemSample && "active"}`}
														onClick={() => setRedeem(false)}
													>
														{t("no")}
													</button>
												</div>
											</div>
										</div>

										{redeemSample ? (
											<div className="mt-3 p-0">
											<div className="px-4 text-center">
											<div id="redeem-sample-question">
												<p>{t("stageQuestion")}</p>
												<div style={{width: "75%", margin: "auto"}} className="form-group vinda-form-group">
												<select
													name="pregnancyStage"
													id="pregnancyStage"
													className="form-select vinda-form-input"
													value={data.pregnancyStage}
													onChange={handleChange}
													required
													disabled={showOtp}
												>
													<option disabled value="">
														{t("currentstage")}
													</option>
													<option value="trying">{t("trying")}</option>
													<option value="pregnant">{t("pregnant")}</option>
													<option value="oneChild">{t("oneChild")}</option>
													<option value="moreChild">{t("moreChild")}</option>
												</select>
											</div>
											{
											data.pregnancyStage === "pregnant" ?
											(<div style={{width: "75%", margin: "auto", marginTop: "20px"}} className="form-group vinda-form-group">
												<DatePicker
													selected={data.edb}
													// maxDate={new Date(moment().subtract(15, "years"))}
													// includeDateIntervals={[{ start: new Date(moment().subtract(100, "years")), end: new Date(moment().subtract(15, "years")) }]}
													onChange={date => setData({ ...data, edb: date })}
													id="edb"
													name="edb"
													className="form-control vinda-form-input"
													placeholderText={t("edb")}
													showYearDropdown
													showMonthDropdown
													dropdownMode="select"
													dateFormat="dd/MM/yyyy"
													minDate={minDate}
													required
												/>
											</div>) : null
											}
											</div>
										</div>
												<div className="choice-parent">
													<p className="choice-title">{t("diapersize")}:</p>

													<div className="choice-container">
														<button
															type="button"
															onClick={() => handleChoose("size", "nb")}
															style={selectedStyle("size", "nb")}
														>
															NB
														</button>
														<button
															type="button"
															onClick={() => handleChoose("size", "s")}
															style={selectedStyle("size", "s")}
														>
															S
														</button>
														<button
															type="button"
															onClick={() => handleChoose("size", "m")}
															style={selectedStyle("size", "m")}
														>
															M
														</button>
														<button
															type="button"
															onClick={() => handleChoose("size", "l")}
															style={selectedStyle("size", "l")}
														>
															L
														</button>
														<button
															type="button"
															onClick={() => handleChoose("size", "xl")}
															style={selectedStyle("size", "xl")}
														>
															XL
														</button>
														{/* <button
															type="button"
															onClick={() => handleChoose("size", "xxl")}
															style={selectedStyle("size", "xxl")}
														>
															XXL
														</button> */}
													</div>
												</div>

												<hr style={{ color: "white", opacity: 1, margin: "30px 0" }} />
												<div id="redeem-sample-container" className="mt-4 text-center">
													{sample && sample.data && sample.data.length ? (
														sample.data.filter(item => item.size === selected.size).length ? (
															sample.data
																.filter(item => item.size === selected.size)
																.map(item => (
																	<div
																		key={item.id}
																		className="redeem-sample-item"
																		onClick={() => setSampleId(item.id)}
																	>
																		<img
																			src={item.itemImage[pageReducer.language]}
																			alt="sample"
																			className="img-fluid"
																		/>

																		<input
																			type="checkbox"
																			className="form-check-input"
																			checked={item.id === sampleId}
																			readOnly
																		/>
																	</div>
																))
														) : (
															<p className="m-auto" style={{ color: "white" }}>
																{t("noavailablesampleswith")}
																<br />
																{t("pleaseselect")}
															</p>
														)
													) : (
														<p style={{ color: "white" }}>- {t("noavailablesamples")} -</p>
													)}
												</div>

												<div className="mt-5">
													<Link to={`/${pageReducer.language}`} style={{ color: "white" }}>
														<small>
															<strong>
																{t("terms")} &amp; {t("condition")}
															</strong>
														</small>
													</Link>
												</div>
											</div>
										) : null}
									</div>
								}
							/>
						</div>

						<div className="pb-5 mt-5" id="SignUp">
							<div className="text-center">
								<h1 className="addToCartTitle" style={{ color: "white" }}>
									{t("dbc")}
								</h1>
								<p style={{ fontWeight: "bold", color: "white", lineHeight: "1em", margin: "20px 0" }}>
									{t("signupasmember")}!
								</p>
							</div>

							<div className="choose-auth-container">
								{/* <FacebookLogin
								appId={process.env.REACT_APP_FACEBOOK_APPID}
								render={renderProps => (
									<button
										type="button"
										id="facebook-button"
										className="choose-auth-button mb-2"
										onClick={renderProps.onClick}
										disabled={renderProps.disabled}
									>
										<AiFillFacebook className="me-5 p-0 m-0 auth-icon" />
										{t("fbsignup")}
									</button>
								)}
								onFail={handleLoginFailure}
								scope="email,public_profile,pages_user_gender"
								fields="name,email,gender"
								onSuccess={handleFBLogin}
								onProfileSuccess={handleFBProfile}
							/> */}
								<FacebookLogin
									appId={process.env.REACT_APP_FACEBOOK_APPID}
									render={renderProps => (
										<button
											type="button"
											id="facebook-button"
											className="choose-auth-button mb-2"
											onClick={renderProps.onClick}
											disabled={renderProps.disabled}
										>
											<AiFillFacebook className="me-5 p-0 m-0 auth-icon" />
											{t("fbsignup")}
										</button>
									)}
									textButton="Facebook"
									callback={handleFBLogin}
									onFailure={handleLoginFailure}
									scope="email,public_profile,user_gender,user_birthday"
									fields="name,email,gender,birthday"
									// disableMobileRedirect={false}
								/>

								{/* <GoogleLogin
								clientId={process.env.REACT_APP_GOOGLE_CLIENTID}
								render={renderProps => (
									<button type="button" id="google-button" className="choose-auth-button" onClick={renderProps.onClick} disabled={renderProps.disabled}>
										<AiFillGoogleCircle className="me-5 m-0 p-0 auth-icon" />
										{t("googsignup")}
									</button>
								)}
								buttonText="Google"
								onSuccess={handleGoogleLogin}
								onFailure={handleLoginFailure}
								scope="profile email https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.phonenumbers.read"
							/> */}
							</div>

							<div className="mt-3" id="fancy-div">
								<div className="div-line" style={{ borderColor: "white", width: "auto" }}></div>
								<p style={{ color: "white" }}>{t("signupbelow")}</p>
								<div className="div-line" style={{ borderColor: "white", width: "auto" }}></div>
							</div>

							<form onSubmit={handleSubmit} className="vinda-form">
								<div disabled={showOtp ? "disabled" : ""}>
									<div className="form-group vinda-form-group">
										<input
											type="text"
											name="name"
											id="name"
											className="form-control vinda-form-input"
											placeholder={t("fullname")}
											value={data.name}
											onChange={handleChange}
											required
											disabled={showOtp}
										/>
										{inputError.name ? <span style={{ color: "red" }}>{inputError.name}</span> : null}
									</div>

									<div className="form-group vinda-form-group">
										<div className="input-group">
											<span className="input-group-text">6</span>
											<input
												type="text"
												name="number"
												id="number"
												className="form-control vinda-form-input"
												placeholder={t("mobile")}
												value={data.number}
												onChange={handleChange}
												required
												disabled={showOtp}
											/>
										</div>

										{inputError.number ? <span style={{ color: "red" }}>{inputError.number}</span> : null}
									</div>

									<div className="form-group vinda-form-group">
										<input
											type="email"
											name="email"
											id="email"
											className="form-control vinda-form-input"
											placeholder={t("email")}
											value={data.email}
											onChange={handleChange}
											required
											disabled={showOtp}
										/>
										{inputError.email ? <span style={{ color: "red" }}>{inputError.email}</span> : null}
									</div>

									<div className="form-group vinda-form-group">
										<input
											type="password"
											name="password"
											id="password"
											className="form-control vinda-form-input"
											placeholder={t("password")}
											value={data.password}
											onChange={handleChange}
											required
											disabled={showOtp}
										/>
									</div>
									{data.password ? (
										<div>
											<ProgressBar
												variant={getPasswordStrengthColor()}
												now={(passwordStrength + 1) * 25}
												label={`${(passwordStrength + 1) * 25}%`}
												style={{ width: "30%", alignSelf: "flex-end" }}
											/>
											<div style={{ color: getPasswordStrengthColor(), textAlign: "left", fontSize: "10px" }}>
												{getPasswordStrengthText(passwordStrength)}
											</div>
										</div>
									) : null}

									<div className="form-group vinda-form-group">
										<DatePicker
											selected={data.dob}
											maxDate={new Date(moment().subtract(15, "years"))}
											// includeDateIntervals={[{ start: new Date(moment().subtract(100, "years")), end: new Date(moment().subtract(15, "years")) }]}
											onChange={date => setData({ ...data, dob: date })}
											id="dob"
											name="dob"
											className="form-control vinda-form-input"
											placeholderText={t("dob")}
											showYearDropdown
											showMonthDropdown
											dropdownMode="select"
											dateFormat="dd/MM/yyyy"
											required
											disabled={showOtp}
										/>
									</div>

									<div className="row">
										<div className="form-group vinda-form-group col me-1">
											<select
												name="gender"
												id="gender"
												className="form-select vinda-form-input"
												value={data.gender}
												onChange={handleChange}
												required
												disabled={showOtp}
											>
												<option disabled value="">
													{t("gender")}
												</option>
												<option value="male">{t("male")}</option>
												<option value="female">{t("female")}</option>
											</select>
										</div>

										<div className="form-group vinda-form-group col ms-1">
											<select
												name="race"
												id="race"
												className="form-select vinda-form-input"
												value={data.race}
												onChange={handleChange}
												required
												disabled={showOtp}
											>
												<option disabled value="">
													{t("race")}
												</option>
												<option value="malay">Malay</option>
												<option value="chinese">Chinese</option>
												<option value="indian">Indian</option>
												<option value="other">{t("others")}</option>
											</select>
										</div>
									</div>

									{redeemSample ? (
										<>
											<div className="form-group vinda-form-group">
												<textarea
													name="line1"
													id="line1"
													className="form-control vinda-form-input"
													placeholder={t("address")}
													value={data.line1}
													onChange={handleChange}
													required
												></textarea>
												{/* <input
											type="text"
											name="line1"
											id="line1"
											className="form-control vinda-form-input"
											placeholder={t("addrline1")}
											value={data.line1}
											onChange={handleChange}
											required
										/> */}
												{/* <input
											type="text"
											name="line2"
											id="line2"
											className="form-control mt-2"
											placeholder={t("addrline2")}
											value={data.line2}
											onChange={handleChange}
											required
										/> */}
											</div>

											<div className="row">
												<div className="form-group vinda-form-group col me-1">
													<Select
														options={dropdown.states}
														onChange={e => setData({ ...data, state: e.value })}
														placeholder={t("state")}
														value={dropdown.states.filter(x => x.value === data.state)}
													/>
												</div>
												<div className="form-group vinda-form-group col ms-1">
													<Select
														options={dropdown.cities}
														onChange={e => setData({ ...data, city: e.value })}
														placeholder={t("city")}
														value={dropdown.cities.filter(x => x.value === data.city)}
														isDisabled={!data.state}
													/>
												</div>
											</div>

											<div className="row">
												<div className="form-group vinda-form-group col me-1">
													<Select
														options={dropdown.postcodes}
														onChange={e => setData({ ...data, postcode: e.value })}
														placeholder={t("postcode")}
														value={dropdown.postcodes.filter(x => x.value === data.postcode)}
														isDisabled={!data.state || !data.city}
													/>
												</div>
												<div className="form-group vinda-form-group col ms-1">
													<input
														type="text"
														name="country"
														id="country"
														className="form-control vinda-form-input"
														placeholder={t("postcode")}
														value={data.country}
														disabled
														readOnly
														required
													/>
												</div>
											</div>

											<div className="form-group vinda-form-group">
												<input
													type="text"
													className="form-control vinda-form-input"
													placeholder={t("producttype")}
													value={
														sample.data.filter(x => x.id === sampleId)[0]
															? sample.data.filter(x => x.id === sampleId)[0].itemName[currentLanguage]
															: ""
													}
													readOnly
													disabled
													style={{ backgroundColor: "white" }}
												/>
											</div>
										</>
									) : null}

									<div className="form-group vinda-form-group">
										<select
											name="pregnancyStage"
											id="pregnancyStage"
											className="form-select vinda-form-input"
											value={data.pregnancyStage}
											onChange={handleChange}
											required
											disabled={showOtp}
										>
											<option disabled value="">
												{t("currentstage")}
											</option>
											<option value="trying">{t("trying")}</option>
											<option value="pregnant">{t("pregnant")}</option>
											<option value="oneChild">{t("oneChild")}</option>
											<option value="moreChild">{t("moreChild")}</option>
										</select>
									</div>
									{data.pregnancyStage === "pregnant" ? (
										<div className="form-group vinda-form-group">
											<DatePicker
												selected={data.edb}
												// maxDate={new Date(moment().subtract(15, "years"))}
												// includeDateIntervals={[{ start: new Date(moment().subtract(100, "years")), end: new Date(moment().subtract(15, "years")) }]}
												onChange={date => setData({ ...data, edb: date })}
												id="edb"
												name="edb"
												className="form-control vinda-form-input"
												placeholderText={t("edb")}
												showYearDropdown
												showMonthDropdown
												dropdownMode="select"
												dateFormat="dd/MM/yyyy"
												minDate={minDate}
												required
											/>
										</div>
									) : null}

									{data.pregnancyStage === "" ? null : (
										<div>
											{data.pregnancyStage === "moreChild" ||
											data.pregnancyStage === "pregnant" ||
											data.pregnancyStage === "trying" ? (
												<>
													<div style={{ width: "auto" }} className="mt-3" id="fancy-div">
														<div className="div-line" style={{ borderColor: "white" }}></div>
														<div>
															<p style={{ color: "white" }}>{t("childInformation")}</p>
														</div>
														<div className="div-line" style={{ borderColor: "white" }}></div>
													</div>
													<p style={{ color: "#d7d7d7" }}>{t("applicableChild")}</p>
												</>
											) : null}
											{[...Array(childCount)].map((_, index) => (
												<div key={index}>
													<div style={{ width: "auto" }} className="mt-3" id="fancy-div">
														<div className="div-line" style={{ borderColor: "white" }}></div>
														<div>
															<p style={{ color: "white" }}>
																{t("informasiAnak")} {childNumbers[index + 1]} {t("childrenInformation")}
															</p>
														</div>
														<div className="div-line" style={{ borderColor: "white" }}></div>
													</div>
													<div className="form-group vinda-form-group">
														<DatePicker
															selected={data[`childDob${index}`]}
															onChange={date => setData({ ...data, [`childDob${index}`]: date })}
															id={`childDob${index}`}
															name={`childDob${index}`}
															className="form-control vinda-form-input"
															placeholderText={t("dob")}
															showYearDropdown
															showMonthDropdown
															dropdownMode="select"
															dateFormat="dd/MM/yyyy"
															required={data.pregnancyStage !== "trying" && data.pregnancyStage !== "pregnant"}
															disabled={showOtp}
														/>
													</div>
													{/* <div className="form-group vinda-form-group col me-1">
									<select
									name={`childGender${index}`}
									id={`childGender${index}`}
									className="form-select vinda-form-input"
									value={data[`childGender${index}`]}
									onChange={handleChange}
									 required={data.pregnancyStage !== "trying" && data.pregnancyStage !== "pregnant"}
									disabled={showOtp}
									>
									<option disabled selected value="">
										{t("gender")} {data.pregnancyStage === "trying" || data.pregnancyStage === "pregnant" ?
										"(Optional)" : ''}
									</option>
									<option value="male">{t("male")}</option>
									<option value="female">{t("female")}</option>
									<option disabled hidden>
										{/* Placeholder option without a value 
									</option>
									</select>
									</div> */}
												</div>
											))}
											{(data.pregnancyStage === "moreChild" ||
												data.pregnancyStage === "pregnant" ||
												data.pregnancyStage === "trying") &&
											childCount < 5 ? (
												<p style={{ color: "white", fontSize: "14px", cursor: "pointer" }} onClick={handleAddChild}>
													{t("addChildren")}
												</p>
											) : null}
										</div>
									)}
									<div className="form-group vinda-form-group">
										<input
											type="text"
											name="referCode"
											id="referCode"
											className="form-control vinda-form-input"
											placeholder={t("referralcode")}
											value={data.referCode}
											onChange={handleChange}
											disabled={showOtp}
										/>
									</div>
								</div>

								{showOtp ? (
									<>
										<div className="form-group vinda-form-group">
											<div className="input-group">
												<input
													type="text"
													id="otp"
													name="otp"
													placeholder="Key in the OTP Code to Verify Your Account"
													className="form-control vinda-form-input"
													value={otp}
													onChange={e => setOtp(e.target.value)}
													required
													maxLength="4"
												/>
											</div>

											{/* <h6 style={{marginTop: "10px", textAlign: "left", color: "white", textDecoration: "underline"}} onClick={handleResendOTP} >Resend OTP</h6> */}
											<h6 style={{ marginTop: "10px", textAlign: "left", color: "grey", textDecoration: "underline" }}>
												{timerStart ? (
													<span>
														{" "}
														<u>
															<b>Resend OTP 0:{timerDuration}</b>
														</u>
													</span>
												) : (
													<span onClick={handleResendOTP}>
														{" "}
														<b>
															<u style={{ color: "white" }}>Resend OTP</u>
														</b>
													</span>
												)}
											</h6>
										</div>
									</>
								) : (
									<></>
								)}

								<div className="form-group vinda-form-group mt-3" style={{ color: "white" }}>
									<label>{t("readourprivacy")}</label>

									<div className="form-check form-check-inline">
										<input type="checkbox" className="form-check-input agreement-checkbox" required />
										<label className="form-check-label">
											{t("ihaveread")}{" "}
											<a
												href={`https://www.drypers.com.my/${currentLanguage}/privacy-policy/#privacy-policy`}
												target="_blank"
												rel="noreferrer"
												/*onClick={() => goToLinks("privacypolicy")}*/ style={{
													cursor: "pointer",
													color: "#80bd00",
													fontWeight: "bold",
													textDecoration: "none"
												}}
											>
												{t("infoonper")}
											</a>{" "}
											{t("iagreewith")}{" "}
											<a
												href={`https://www.drypers.com.my/${currentLanguage}/terms-conditions/`}
												target="_blank"
												rel="noreferrer"
												/*onClick={() => goToLinks("termsofuse")}*/ style={{
													cursor: "pointer",
													color: "#80bd00",
													fontWeight: "bold",
													textDecoration: "none"
												}}
											>
												{t("termsofuse")}
											</a>
										</label>
									</div>

									{/* <div className="form-check form-check-inline">
									<input
										type="checkbox"
										className="form-check-input agreement-checkbox"
										id="receiveMail"
										name="receiveMail"
										value={data.receiveMail}
										onChange={() => setData({ ...data, receiveMail: !data.receiveMail })}
										checked={data.receiveMail}
									/>
									<label htmlFor="receiveMail" className="form-check-label">
										{t("yesiliketo")}
									</label>
								</div> */}
								</div>

								<div className="form-group vinda-form-group text-center mt-4">
									<Loader
										component={
											<input
												type="submit"
												value={t("signupnow")}
												className="vinda-form-submit btn fw-bold"
												style={{ backgroundColor: "#80bd00", width: "100%" }}
											/>
										}
									/>
								</div>
							</form>

							<div className="mt-5 vinda-form">
								<div className="form-group vinda-form-group text-center">
									<div className="text-start">
										<p className="fw-bold m-0 p-0" style={{ color: "white" }}>
											{t("existingmember")}
										</p>
									</div>
									<button
										type="button"
										className="vinda-form-submit btn fw-bold"
										onClick={() => history.push(`/${pageReducer.language}/signin`)}
										style={{ border: "2px #80bd00 solid", backgroundColor: "#290095", width: "100%" }}
									>
										{t("loginexisting")}
									</button>
								</div>
							</div>
						</div>
					</section>

					<div className="interactive-section">
						<h1 className="dbc-title">{t("becauseparent")}</h1>

						<div id="interactive-container">
							<div className="interactive-box-container">
								<InteractiveBox
									imgSrc="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/landingPage/interactive1.png"
									iconSrc="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/giftBox.png"
									overlayTitle={t("welcomegift")}
									overlayDescription={t("freegoodies")}
									mainDescription={t("asanewmember")}
								/>
							</div>
							<div className="interactive-box-container">
								<InteractiveBox
									imgSrc="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/landingPage/interactive2.png"
									iconSrc="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/diaper.png"
									overlayTitle={t("freesamples")}
									overlayDescription={t("bethefirst")}
									mainDescription={t("getfirstdips")}
								/>
							</div>
							<div className="interactive-box-container">
								<InteractiveBox
									imgSrc="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/landingPage/interactive3.png"
									iconSrc="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/handPoint.png"
									overlayTitle={t("redeempoints")}
									overlayDescription={t("turnpoints")}
									mainDescription={t("eachtime")}
								/>
							</div>
							<div className="interactive-box-container">
								<InteractiveBox
									imgSrc="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/landingPage/interactive4.png"
									iconSrc="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/parent.png"
									overlayTitle={t("pregnancy")}
									overlayDescription={t("babybumbum")}
									mainDescription={t("whetherplan")}
								/>
							</div>
						</div>

						<div className="text-start">
							<p className="m-0 p-0" style={{ color: "#696969" }}>
								<small>* {t("tnca")}</small>
							</p>
						</div>
					</div>
				</section>

				<ContactUs />

				<div className="desktop-only">
					<Footer />
				</div>
			</div>

			<Modal show={pageReducer.error} onHide={() => clearError()} centered>
				<Modal.Body className="text-center">
					<div>
						<p className="addToCartTitle">
							{pageReducer.error === "You've entered the wrong password"
								? t("error")
								: pageReducer.error === "This user is not registered. Please head to sign up page."
								? t("error")
								: pageReducer.error === "This referral code has reached the maximum referral limit for the month."
								? t("error")
								: pageReducer.error ===
								  "You have already registered with this device. One device is entitled to register for one account."
								? t("error")
								: pageReducer.error ===
								  "You have already registered as a Drypers Baby Club member. Please log in to continue."
								? null
								: pageReducer.error ===
								  "Vindamall account verified. You may proceed to log in with Vindamall email and password. A Drypers Baby Club account will automatically be created for you."
								? null
								: pageReducer.error === "Password not strong enough. Please reset your password."
								? null
								: pageReducer.error ===
								  "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character (@$!%*?&)."
								? t("ssoPasswordValidation")
								: t("error")}
						</p>
						<p className="mt-3 pickSampleModalText">
							{pageReducer.error === "You've entered the wrong password"
								? t("wrongpwd")
								: pageReducer.error === "This user is not registered. Please head to sign up page."
								? t("notRegistered")
								: pageReducer.error === "This referral code has reached the maximum referral limit for the month."
								? t("referralPopup")
								: pageReducer.error ===
								  "You have already registered with this device. One device is entitled to register for one account."
								? t("cookiePopup")
								: pageReducer.error ===
								  "You have already registered as a Drypers Baby Club member. Please log in to continue."
								? t("ssoExist")
								: pageReducer.error ===
								  "Vindamall account verified. You may proceed to log in with Vindamall email and password. A Drypers Baby Club account will automatically be created for you."
								? t("ssoVindamallExist")
								: pageReducer.error === "Password not strong enough. Please reset your password."
								? t("passwordNotStrong")
								: pageReducer.error ===
								  "Password must contain at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character (@$!%*?&)."
								? t("ssoPasswordValidation")
								: pageReducer.error}
						</p>
					</div>

					<div>
						<button className="pickAddressConfirmButton" onClick={() => clearError()}>
							OKAY
						</button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={otpModal} onHide={handleClose} dialogClassName="modal-90w" centered>
				<Modal.Body className="text-center">
					<div>
						<div>
							<p className="addToCartTitle">{t("otpHead")}!</p>
							<p className="mt-3 pickSampleModalText">{t("otpText")}</p>
						</div>
						<div>
							<button className="pickAddressConfirmButton" onClick={() => handleClose()}>
								OKAY
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			{/* <Modal show={ssoModal} onHide={closeSso} dialogClassName="modal-90w" centered>
		<Modal.Header closeButton>
        </Modal.Header>
			<Modal.Body className="text-center">

			<div>
				<h1 style={{ paddingLeft: "30px", paddingRight: "30px" }} className="dashboardNewsTitle">
					{t("ssoUpdate")}
				</h1>
				<h2 style={{ fontSize: "18px", color: 'black', paddingLeft: "10px", paddingRight: "10px" }}
					className="UpdateText">
					{t("ssoUpdate2")}
				</h2>
				<h2 style={{ fontSize: "18px", color: 'black', paddingLeft: "10px", paddingRight: "10px", paddingTop: "20px" }}
					className="UpdateText">
					{t("ssoUpdate3")}
				</h2>
			</div>
			</Modal.Body>
			</Modal> */}

			{/* <Modal show={notification} onHide={() => setNotification(false)} centered>
				<Modal.Body className="text-justify" style={{ whiteSpace: "pre-line" }}>
					<div className="text-end">
						<CloseButton onClick={() => setNotification(false)} />
					</div>
					<p className="text-center fw-bold">{t("announcement")}</p>
					<p>{t("notification")}</p>
				</Modal.Body>
			</Modal> */}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		sample: state.userReducer.samples,
		pageReducer: state.pageReducer,
		currentLanguage: state.pageReducer.language
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getSample: () => dispatch(getSample()),
		registerUser: (data, callback) => dispatch(registerUser(data, callback)),
		verifyUser: (data, callback) => dispatch(verifyUser(data, callback)),
		clearError: () => dispatch(clearError()),
		selectLanguage: language => dispatch(selectLanguage(language))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
