import axios from "axios";
import { IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, AUTH_ADMIN } from "../types";

// login
export const adminLogin = data => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/auth/login", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			dispatch({ type: AUTH_ADMIN, payload: res.data });
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
		});
};

// check session
export const checkAdminSession = () => (dispatch, getState) => {
	const { token } = getState().userReducer;
	if (token) {
		dispatch({ type: IS_LOADING });
		axios
			.post("/api/admin/auth/session", { token })
			.then(res => {
				dispatch({ type: IS_LOADED });
				dispatch({ type: CLEAR_ERROR });
				dispatch({ type: AUTH_ADMIN, payload: res.data });
			})
			.catch(err => {
				dispatch({ type: IS_LOADED });
				dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			});
	}
};

// add brand
export const addBrand = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/brand/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// edit brand
export const editBrand = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/brand/edit", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// add size
export const addSize = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/size/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// add item
export const addItem = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/item/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

//add Item Pregnant
export const addItemPregnant = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/pregnantItem/addPregnant", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// add new item category
export const addCategory = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/category/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback(null, res.data);
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// edit item
export const editItem = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/item/edit", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// edit item pregnant
export const editItemPregnant = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/pregnant/editPregnant", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// add voucher type
export const addVoucherType = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/voucherType/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// generate codes
export const generateCodes = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/code/generate", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// add news
export const addNews = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/news/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

export const addArticle = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/article/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

export const addContest = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/add/contest", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

export const editContest = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/drypers-baby-club/api/admin/setting/edit/contest", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

export const addBlog = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/blog/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

export const addWishingTree = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/wishingtree/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// edit news
export const editNews = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/news/edit", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

export const editArticle = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/article/edit", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

export const editBlog = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/blog/edit", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

export const editAccount = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/account/edit", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// edit wishing
export const editWishingTree = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/wishingtree/edit", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// edit journey
export const editJourney = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/journey/edit", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// add sample question
export const addSampleQuestion = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/sample/question/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// add diaper/sample
export const addDiaper = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/diaper/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// edit diaper/sample
export const editDiaper = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/diaper/edit", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// add donate item
export const addDonateItem = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/item/donate/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// add promoter
export const addPromoter = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/promoter/add", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};

// edit promoter
export const editPromoter = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/admin/setting/promoter/edit", data)
		.then(res => {
			dispatch({ type: IS_LOADED });
			dispatch({ type: CLEAR_ERROR });
			callback();
		})
		.catch(err => {
			dispatch({ type: IS_LOADED });
			callback(err.response.data.error);
		});
};
