import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiOutlineVideoCamera } from "react-icons/ai";
// import { Spinner } from "react-bootstrap";
import { createWorker } from "tesseract.js";
// import axios from "axios";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const worker = createWorker();

// // video config
const vidWidth = window.innerWidth - 60; // can be controlled
const vidHeight = window.innerWidth - 180; // can be controlled
// const vidOffsetTop = 240; // can be controlled
// const vidOffsetLeft = window.innerWidth / 2 - vidWidth / 2; // is centered, but if you want to change also can

// // indicator config
const marginX = 40; // margin left and right, can be controlled
const indWidth = vidWidth - marginX; // 100% width - margin, can be changed if you want
const indHeight = 80; // can be controlled
// const indOffsetTop = vidOffsetTop + vidHeight / 2 - indHeight / 2; // is centered, if you want to change also can
// const indOffsetLeft = window.innerWidth / 2 - indWidth / 2; // is centered, if you want to change also can

const TesseractCam = ({ setCode, setScanning, language }) => {
	const { t } = useTranslation();
	const myVideo = useRef();
	const myStream = useRef();
	const scannedCodes = useRef();
	const testCanvas = useRef();
	const [isMounted, setMounted] = useState(false);
	const [done, setDone] = useState(false);
	const [open, setOpen] = useState(false);
	const [camSelection, setCamSelection] = useState(null);
	// const [b64Body, setB64Body] = useState("");
	// const [isLoading, setLoading] = useState(false);
	// const [test, setTest] = useState("");

	useEffect(() => {
		setMounted(true);
		if (isMounted) {
			openCam();
		}
		return () => {
			setMounted(false);
			offCam();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMounted]);

	const openCam = opt => {
		console.log("OPEN CAM");
		const options = opt || { video: { facingMode: "environment" }, audio: false };
		isMounted &&
			navigator.mediaDevices
				.getUserMedia(options)
				.then(stream => {
					if (!stream || !stream.getVideoTracks().length || !isMounted) {
						alert("Device don't have a camera");
					} else {
						if (isMounted) myVideo.current.srcObject = stream;
						if (isMounted) myVideo.current.play();
						if (isMounted) myStream.current = stream;
						if (isMounted) scannedCodes.current = {};
						// if (isMounted) requestAnimationFrame(tick);

						(async () => {
							if (isMounted) {
								await worker.load();
								await worker.loadLanguage("eng");
								await worker.initialize("eng");
								requestAnimationFrame(tick);
							}
						})().then(() => console.log("Cam opened"));
					}
				})
				.catch(err => alert(err.message));
	};

	const offCam = () => {
		console.log("Cam closed");
		myStream && myStream.current && myStream.current.getTracks().forEach(x => x.stop());
		if (myVideo.current && myVideo.current.srcObject && myVideo.current.src) myVideo.current.src = null;
		if (myVideo.current && myVideo.current.srcObject && myVideo.current.srcObject.getTracks().length)
			myVideo.current.srcObject.getTracks().forEach(x => x.stop());
		if (myVideo && myVideo.current) myVideo.current.pause();
	};

	const switchCam = () => {
		if (isMounted && !done) {
			if (!camSelection) {
				navigator.mediaDevices
					.enumerateDevices()
					.then(devices => {
						const videoInput = devices.filter(device => device.kind === "videoinput").map(x => ({ id: x.deviceId, label: x.label }));
						setCamSelection(videoInput);
						setOpen(true);
					})
					.catch(err => alert(err.message));
			} else setOpen(true);
		}
	};

	const selectCam = deviceId => {
		const option = {
			video: {
				deviceId: { exact: deviceId }
			},
			audio: false
		};
		openCam(option);
		setOpen(false);
	};

	// const detectCode = () => {
	// 	// const b64Str = `data:image/png;base64,${b64Body}`;
	// 	// setTest(b64Str);
	// 	setLoading(true);
	// 	const data = JSON.stringify({
	// 		requests: [
	// 			{
	// 				image: {
	// 					content: b64Body
	// 				},
	// 				features: [
	// 					{
	// 						type: "TEXT_DETECTION"
	// 					}
	// 				]
	// 			}
	// 		]
	// 	});

	// 	axios
	// 		.post(`https://vision.googleapis.com/v1/images:annotate?key=${process.env.REACT_APP_GOOGLE_APIKEY}`, data, {
	// 			headers: {
	// 				"Content-Type": "application/json"
	// 			}
	// 		})
	// 		.then(res => {
	// 			console.log(res.data.responses[0]);
	// 			if (
	// 				res.data &&
	// 				res.data.responses &&
	// 				res.data.responses.length &&
	// 				res.data.responses[0].textAnnotations &&
	// 				res.data.responses[0].textAnnotations.length
	// 			) {
	// 				const processedResults = res.data.responses[0].textAnnotations
	// 					.map(x => {
	// 						const { description } = x;
	// 						const regex = /[a-zA-Z0-9]/gi;
	// 						const processedText = description
	// 							.match(regex)
	// 							.filter(y => y)
	// 							.join("");

	// 						if (processedText.length === 10) return processedText;
	// 						return "";
	// 					})
	// 					.filter(x => x);

	// 				if (processedResults.length) {
	// 					setDone(true);
	// 					setCode(processedResults[0]);
	// 					setScanning(false);
	// 				} else {
	// 					setLoading(false);
	// 				}
	// 			} else setLoading(false);
	// 		})
	// 		.catch(err => {
	// 			setLoading(false);
	// 			console.log(err);
	// 		});
	// };

	const tick = async () => {
		if (myVideo && myVideo.current && myVideo.current.readyState === myVideo.current.HAVE_ENOUGH_DATA && isMounted && !done) {
			const canvas = document.createElement("canvas");
			// const vidStyleData = myVideo.current.getBoundingClientRect();
			canvas.width = indWidth;
			canvas.height = indHeight;
			// canvas.width = myVideo.current.clientWidth;
			// canvas.height = myVideo.current.clientHeight;
			testCanvas.current.width = indWidth;
			testCanvas.current.height = indHeight;
			// canvas.id = "replaceThis";
			// canvas.className = "m-auto";
			// canvas.style.border = "1px lime solid";

			const image = myVideo.current;
			// source
			const sx = marginX + 20;
			const sy = myVideo.current.clientHeight;
			const sWidth = canvas.width * 2;
			const sHeight = canvas.height;
			// destination
			const dx = 0;
			const dy = 0;
			const dWidth = canvas.width;
			const dHeight = canvas.height;

			canvas.getContext("2d").drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
			// canvas.getContext("2d").drawImage(image, 0, 0, canvas.width, canvas.height);
			testCanvas.current.getContext("2d").drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);

			// tesseract
			const {
				data: { text }
			} = await worker.recognize(canvas);
			const regex = /[a-zA-Z0-9]/gi;
			const scannedText =
				text &&
				text.match(regex) &&
				text
					.match(regex)
					.filter(x => x)
					.join("");
			// scannedText && console.log(scannedText);
			if (scannedText && scannedText.length === 10) {
				console.log(scannedText);

				if (!scannedCodes.current[scannedText]) scannedCodes.current[scannedText] = 1;
				else {
					scannedCodes.current[scannedText]++;
					if (scannedCodes.current[scannedText] > 2) {
						setDone(true);
						setCode(scannedText);
						setScanning(false);
					}
				}
			}
			// const replace = document.querySelector("#replaceThis");
			// document.querySelector("#canvas-holder").replaceChild(canvas, replace);
			// const b64 = canvas.toDataURL();
			// const regex = /^data:image\/\w+;base64,/;
			// const body = b64.replace(regex, "");
			// setB64Body(body);
		}
		!done && requestAnimationFrame(tick);
	};
	return (
		<>
			<div style={{ position: "relative", textAlign: "center", margin: 0, padding: 0 }}>
				<video
					ref={myVideo}
					muted
					autoPlay
					playsInline
					width={vidWidth}
					height={vidHeight}
					style={{
						objectFit: "cover",
						margin: "0 auto"
					}}
				></video>

				<div
					style={{
						width: `${indWidth}px`,
						height: `${indHeight}px`,
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)"
					}}
					className="scan-box"
				>
					<div className="underline-code" style={{ width: indWidth / 10 - 10 }}></div>
					<div className="underline-code" style={{ width: indWidth / 10 - 10 }}></div>
					<div className="underline-code" style={{ width: indWidth / 10 - 10 }}></div>
					<div className="underline-code" style={{ width: indWidth / 10 - 10 }}></div>
					<div className="underline-code" style={{ width: indWidth / 10 - 10 }}></div>
					<div className="underline-code" style={{ width: indWidth / 10 - 10 }}></div>
					<div className="underline-code" style={{ width: indWidth / 10 - 10 }}></div>
					<div className="underline-code" style={{ width: indWidth / 10 - 10 }}></div>
					<div className="underline-code" style={{ width: indWidth / 10 - 10 }}></div>
					<div className="underline-code" style={{ width: indWidth / 10 - 10 }}></div>
				</div>
			</div>
			<div className="text-center m-0 p-0">
				<p className="m-0 p-0">
					<small>
						{t("blurrycam")}{" "}
						<strong onClick={switchCam}>
							<span style={{ color: "#223C96", fontWeight: "bold" }}>{t("switch")}</span>
						</strong>
					</small>
				</p>
			</div>

			{/* <div className="my-5 px-5 text-center">
				{isLoading ? (
					<Spinner animation="grow" variant="primary" />
				) : (
					<button type="button" className="btn btn-primary form-control fw-bold" onClick={detectCode}>
						{t("scancode")}
					</button>
				)}
			</div> */}
			{language === "en" ? (
				<img
					src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/key-in-button.png"
					alt="keybtn"
					className="img-fluid"
					style={{ position: "fixed", left: "25px", bottom: "100px" }}
					onClick={() => setScanning(false)}
				/>
			) : (
				<img
					src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/key-in-button-bm.png"
					alt="keybtn"
					className="img-fluid"
					style={{ position: "fixed", left: "25px", bottom: "100px" }}
					onClick={() => setScanning(false)}
				/>
			)}

			{/* <div id="canvas-holder" style={{ border: "1px blue solid", textAlign: "center" }}>
				<canvas id="replaceThis"></canvas>
				<img src={test} alt="test" />
			</div> */}

			<div style={{ textAlign: "center", marginBottom: "100px" }}>
				<canvas ref={testCanvas} style={{ margin: "auto" }}></canvas>
			</div>

			<Modal show={open} onHide={() => setOpen(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title>{t("switch")}</Modal.Title>
				</Modal.Header>

				<Modal.Body className="text-center">
					{camSelection && camSelection.length ? (
						camSelection.map((selection, ind) => (
							<button type="button" key={selection.id} onClick={() => selectCam(selection.id)} className="btn btn-info form-control mb-3">
								<AiOutlineVideoCamera className="me-3" fontSize={20} />
								Camera {ind + 1} {selection.label ? `(${selection.label})` : null}
							</button>
						))
					) : (
						<p>{t("nothing")}</p>
					)}
				</Modal.Body>

				<Modal.Footer>
					<button className="btn btn-secondary btn-block" onClick={() => setOpen(false)}>
						Cancel
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

const mapStateToProps = state => {
	return {
		language: state.pageReducer.language
	};
};

export default connect(mapStateToProps)(TesseractCam);
