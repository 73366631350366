import { useState, useEffect, useMemo } from "react";
import { MDBDataTableV5 } from "mdbreact";
// import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { MdAddCircleOutline } from "react-icons/md";
import axios from "axios";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import Loader from "../../../component/Loader";
import { sampleDashboardColumns } from "../../../config/mdbColumns";

const SampleQuestionDashboard = ({ history, loading, loaded }) => {
	const [data, setData] = useState({
		questions: []
	});


	useEffect(() => {
		loading();
		axios
			.get("/api/admin/fetch/dashboard/sample/question")
			.then(res => {
				setData({
					questions: res.data.questions
				});
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableData = useMemo(() => {
		return {
			columns: sampleDashboardColumns,
			rows: data.questions
				.map((question, ind) => {
					return {
						no: ind + 1,
						title: question.title,
						bmTitle: question.bmTitle || "-",
						createdAt: new Date(question.createdAt).toLocaleString("en-GB"),
						updatedAt: new Date(question.updatedAt).toLocaleString("en-GB"),
						choice: question.sampleQuestionChoices.map(choice => (
							<p className="m-0 p-0" key={choice.id}>
								{choice.title}
							</p>
						)),
						bmChoice: question.sampleQuestionChoices.map(choice => (
							<p className="m-0 p-0" key={choice.id}>
								{choice.bmTitle || "-"}
							</p>
						)),
						type: question.type
					};
				})
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					{/* contents */}
					<h3>Sample Survey Dashboard</h3>

					<div className="mt-5 card">
						<div className="card-header d-flex justify-content-between">
							<h5 className="card-title">Questions</h5>
							<div>
								<button className="btn btn-primary me-2" onClick={() => history.push({ pathname: "/admin/sample/survey/add", brands: data.brands })}>
									<MdAddCircleOutline className="me-2" />
									New Question
								</button>
							</div>
						</div>

						<div className="card-body text-center">
							<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered order={["no", "asc"]} />} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(SampleQuestionDashboard);
