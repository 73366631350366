import { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Spinner, Modal, ProgressBar } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { AiOutlineCheckCircle } from "react-icons/ai";
import QueryString from "query-string";
import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import { AUTH_USER } from "../../../redux/types";

const Verify = ({ location, history, authUser, language }) => {
	const { user, datems } = QueryString.parse(location.search);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState({
		title: "",
		description: ""
	});
	const [countDown, setCountdown] = useState(6);
	const [userData, setUserData] = useState(null);

	useEffect(() => {
		let isMounted = true;

		if (!user || !datems) {
			history.push(`/${language}`);
		} else {
			const thisDate = new Date(parseInt(datems)).getDate();
			const todayDate = new Date().getDate();

			const thisMonth = new Date(parseInt(datems)).getMonth();
			const todayMonth = new Date().getMonth();

			const thisYear = new Date(parseInt(datems)).getFullYear();
			const todayYear = new Date().getFullYear();

			if (thisDate !== todayDate || thisMonth !== todayMonth || thisYear !== todayYear) {
				setError("Link expired, please proceed to register again");
			} else {
				// still today
				if (isMounted) {
					axios
						.post("/api/user/verify", { id: user, datems })
						.then(res => {
							if (isMounted) {
								isMounted && setUserData(res.data.userData);
								isMounted &&
									setSuccess({
										title: res.data.title,
										description: res.data.description
									});
								isMounted && setLoading(false);
								isMounted && setCountdown(5);
							}
						})
						.catch(err => setError(err.response.data.error));
					// setCountdown(5);
					// setSuccess({
					// 	title: "nice",
					// 	description: "nice"
					// });
				}
			}
		}

		return () => (isMounted = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let myInterval = null;
		if (success && success.title && success.description && !loading) {
			myInterval = setTimeout(() => setCountdown(countDown - 0.1), 100);
			console.log(countDown);
			if (countDown < 1) {
				clearTimeout(myInterval);
				myInterval = null;
				setSuccess({
					title: "",
					description: ""
				});
				setLoading(true);
				setCountdown(0);
				authUser(userData);
			}
		}
		return () => {
			clearTimeout(myInterval);
			myInterval = null;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countDown, success, success.title, success.description, loading]);

	if (!user || !datems) {
		return <Redirect to={`/${language}`} />;
	} else {
		return (
			<div id="verification-page">
				<Header />
				<div className="content" style={{ minHeight: "100vh", paddingTop: "5vh" }}>
					{loading ? (
						<Spinner animation="border" variant="success" className="mt-5" />
					) : (
						<div id="verification-message-container">
							<div>
								<AiOutlineCheckCircle className="verification-success fw-bold" />
							</div>
							<div className="verification-message-title">
								<h1 className="fw-bold">{success.title}</h1>
							</div>

							<div className="verification-message-description mt-4">
								<h4 className="fw-bold">{success.description}</h4>
							</div>

							<div className="mt-5">
								<ProgressBar variant="success" now={countDown} max={5} />
							</div>
						</div>
					)}
				</div>
				<div className="desktop-only">
					<Footer />
				</div>

				<Modal show={error} onHide={() => setError(null)} centered>
					<Modal.Body className="text-center">
						<div>
							<p className="addToCartTitle">Error</p>
							<p className="mt-3 pickSampleModalText">{error}</p>
						</div>

						<div>
							<button className="pickAddressConfirmButton" onClick={() => setError(null)}>
								OKAY
							</button>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		language: state.pageReducer.language
	};
};

const mapDispatchToProps = dispatch => {
	return {
		authUser: data => dispatch({ type: AUTH_USER, payload: data })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
