import { useEffect, useState, useMemo } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { MdAddCircleOutline } from "react-icons/md";
import axios from "axios";
import { connect } from "react-redux";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import Loader from "../../../component/Loader";
import { donateDashboardColumns } from "../../../config/mdbColumns";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";

const DonateDashboard = ({ history, loading, loaded }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		loading();
		axios
			.get("/api/admin/fetch/dashboard/donate")
			.then(res => {
				loaded();
				setData(res.data);
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableData = useMemo(
		() => ({
			columns: donateDashboardColumns,
			rows: data.map(x => {
				return {
					...x,
					itemImage: <img src={x.itemImage} alt="donate item" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
					edit: (
						<button type="button" className="btn btn-primary" onClick={() => console.log("edit")}>
							Edit
						</button>
					)
				};
			})
		}),
		[data]
	);

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					{/* contents */}
					<h3>Share a Little Comfort (Point Donation)</h3>

					<div className="mt-5">
						<button type="button" className="btn btn-primary" onClick={() => history.push("/admin/donate/add")}>
							<MdAddCircleOutline className="me-2" />
							Add Item
						</button>

						<div className="mt-5 card">
							<div className="card-header">
								<h5 className="card-title">Items</h5>
							</div>

							<div className="card-body text-center">
								<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(DonateDashboard);
