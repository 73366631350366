import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import csvTemplate from "../../../assets/files/bulkCreateUsersTemplate.csv";
import { MDBDataTableV5 } from "mdbreact";

const BulkCreateUsersDashboard = () => {
	const [file, setFile] = useState(null);
	const [label, setLabel] = useState("");
	const [promoterId, setPromoterId] = useState("");
	const [registerDate, setRegisterDate] = useState("");
	const [warning, setWarning] = useState(true);
	const [loading, setLoading] = useState(false);
	const [promoters, setPromoters] = useState([]);
	const [checkData, setCheckData] = useState(null);
	const [data, setData] = useState(null);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await axios.get("/api/admin/fetch/dashboard/bulkCreateUsers");
				setData(data.data);
			} catch (error) {
				const err =
					error && error.response && error.response.data && error.response.data.error
						? error.response.data.error
						: error.message;
				alert(err);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await axios.get("/api/admin/fetch/dashboard/promoter");
				setPromoters(data.data);
			} catch (error) {
				const err =
					error && error.response && error.response.data && error.response.data.error
						? error.response.data.error
						: error.message;
				alert(err);
			}
		})();
	}, []);

	const handleFileUpload = e => {
		const uploadedFile = e.target.files[0] || null;
		if (uploadedFile) {
			const fileType = uploadedFile.type;
			if (fileType !== "text/csv") return alert("Please upload a CSV file");
			else setFile(uploadedFile);
		}
	};

	const handleSubmit = async e => {
		e.preventDefault();
		if (!file) return alert("Please upload a file");
		else {
			setLoading(true);
			const formData = new FormData();
			formData.append("file", file);
			try {
				const { data } = await axios.post("/api/admin/setting/bulkCreateUsers/check", formData, {
					headers: { "Content-Type": "multipart/form-data" }
				});
				setCheckData(data);

				setLoading(false);
			} catch (error) {
				const err =
					error && error.response && error.response.data && error.response.data.error
						? error.response.data.error
						: error.message;
				alert(err);
				setLoading(false);
			}
		}
	};

	const handleConfirmSubmit = async () => {
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("file", file);
			formData.append("label", label);
			formData.append("promoterId", promoterId);
			formData.append("registerDate", registerDate);
			await axios.post("/api/admin/setting/bulkCreateUsers", formData, {
				headers: { "Content-Type": "multipart/form-data" }
			});
			setCheckData(null);
			setLoading(false);
		} catch (error) {
			const err =
				error && error.response && error.response.data && error.response.data.error
					? error.response.data.error
					: error.message;
			alert(err);
			setLoading(false);
		}
	};

	const renderColor = ({ number, email }) => {
		let color = "black";
		const numberRegex = /^(01)[0-46-9]([0-9]){7,8}$/;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const numberResult = number ? numberRegex.test(number) : true;
		const emailResult = email ? emailRegex.test(email) : true;

		if (!numberResult || !emailResult) color = "red";
		return color;
	};

	return (
		<div className="admin-page">
			<AdminSidebar />
			<div className="admin-body">
				<AdminTopbar />
				<div className="admin-content">
					<h3>Bulk Create Users</h3>

					<section className="mt-5">
						{/* dropdown, file input on top */}
						<form onSubmit={handleSubmit}>
							<p>Step 1: Download Upload Template</p>
							<a href={csvTemplate} download="bulkCreateUsersTemplate.csv" target="_blank" rel="noreferrer">
								Download Template Here
							</a>
							<hr />
							<p>Step 2: Fill in details and upload</p>
							<input
								type="file"
								id="bulkCreate"
								name="bulkCreate"
								onChange={handleFileUpload}
								accept="text/csv"
								required
							/>
							<hr />
							<p>
								Step 3: Label for reference
								<br />
								<small>
									<strong>eg: WOOP Batch 9</strong>
								</small>
							</p>
							<input
								type="text"
								id="label"
								name="label"
								value={label}
								onChange={e => setLabel(e.target.value)}
								required
							/>
							<hr />
							<p>Step 4: Optional Fields</p>
							<div className="d-flex">
								<div className="p-1" style={{ border: "1px black solid" }}>
									<p>
										Promoter:
										<br />
										<small>
											These list of users will be registered under which promoter? Leave empty if no promoter.
										</small>
									</p>
									<select
										id="promoterId"
										name="promoterId"
										value={promoterId}
										onChange={e => setPromoterId(e.target.value)}
									>
										<option value="">{promoterId ? "Clear Selection" : "Select Promoter"}</option>
										{promoters.map(promoter => (
											<option key={promoter.value} value={promoter.value}>
												{promoter.label}
											</option>
										))}
									</select>
								</div>

								<div className="p-1" style={{ border: "1px black solid" }}>
									<p>
										Register Date:
										<br />
										<small>
											These list of users will be registered under which date? Leave empty for default value (today)
										</small>
									</p>
									<input
										type="date"
										id="registerDate"
										name="registerDate"
										value={registerDate}
										onChange={e => setRegisterDate(e.target.value)}
									/>
									<button type="button" onClick={() => setRegisterDate("")}>
										Clear Date
									</button>
								</div>
							</div>

							<input
								type="submit"
								value="Check Validity"
								className="btn btn-primary form-control mt-3"
								disabled={loading || !!checkData}
							/>
						</form>
					</section>

					<section className="mt-5">
						<h4>Upload history</h4>

						<MDBDataTableV5
							data={{
								columns: [
									{ label: "ID", field: "id" },
									{ label: "Label", field: "label" },
									{ label: "Upload Date", field: "createdAt" },
									{ label: "Download", field: "fileUrl" }
								],
								rows:
									data && data.length
										? data.map(x => ({
												...x,
												fileUrl: (
													<a href={x.fileUrl} download className="btn btn-primary">
														Download
													</a>
												)
										  }))
										: []
							}}
							striped
							hover
							responsive
							bordered
						/>
					</section>
				</div>
			</div>

			<Modal show={warning} onHide={() => setWarning(false)} centered>
				<Modal.Body>
					<div>
						<p className="fw-bold">Take note</p>
						<ol>
							<li>If user already exist in DBC, that particular row will be skipped and ignored</li>
							<li>
								If choosing to register list of users under a certain promoter, existing users will not be
								changing/adding to the promoter
							</li>
							<li>Users with invalid email or phone number format will be skipped and ignored</li>
							<li>If in the same file contains 2 same number / 2 same email, only one will be registered</li>
							<li>User exist status is based on email address and phone number</li>
							<li>Phone number format should be (601xxxxxxxxx)</li>
							<li>
								Avoid (comma ,) because this CSV (comma separated values) we will be taking the values splitting by
								comma (,). Except for the last column (address street)
							</li>
							<li>
								Some of the fields are not compulsory to be filled in, if not filled in will be having default values
							</li>
							<li>Default values: Race = "other", Gender = "female", Is Pregnant?= "No", ...</li>
							<li>If to add address, must have all address values present (state, city, postcode, street)</li>
						</ol>
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={!!checkData} onHide={() => console.log("cannot close")} centered>
				<Modal.Body>
					<div>
						<h2>Confirmation</h2>
						<p>
							Total Users Input: <strong>{checkData?.data?.length || 0}</strong>
						</p>
						<p>
							Total Issue Users: <strong>{checkData?.issueUsers?.length || 0}</strong>
						</p>

						<hr />

						<p>
							Issue Users: <small>(* These are the users that will be skipped and ignored if confirmed)</small>
						</p>

						<ol>
							{checkData?.issueUsers.map((user, i) => (
								<li key={i} style={{ fontSize: "12px", border: "1px black solid", padding: "4px" }}>
									<p className="m-0 p-0">
										Name: <strong>{user.name}</strong>
									</p>
									<p className="m-0 p-0">
										Phone Number: <strong style={{ color: renderColor({ number: user.number }) }}>{user.number}</strong>
									</p>
									<p className="m-0 p-0">
										Email: <strong style={{ color: renderColor({ email: user.email }) }}>{user.email}</strong>
									</p>
								</li>
							))}
						</ol>

						<hr />

						<p className="fw-bold">
							Total {(checkData?.data?.length || 0) - (checkData?.issueUsers?.length || 0)} users will be registered{" "}
							{promoterId
								? `under the "${promoters.find(promoter => promoter.value === promoterId).label}" promoter`
								: null}
							{registerDate
								? `, register date for these users will be ${new Date(registerDate).toLocaleDateString("en-GB")}`
								: null}
						</p>

						<div className="text-center">
							<button type="button" className="me-1 btn btn-secondary" onClick={() => setCheckData(null)}>
								Upload Again
							</button>
							<button
								type="button"
								className="fw-bold ms-1 btn btn-warning"
								disabled={loading}
								onClick={handleConfirmSubmit}
							>
								Confirm Submit
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default BulkCreateUsersDashboard;
