import { useState, useEffect, useMemo } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { connect } from "react-redux";
import { MdAddCircleOutline } from "react-icons/md";
import axios from "axios";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import Loader from "../../../component/Loader";
import { articleDashboardColumns } from "../../../config/mdbColumns";

const ArticleDashboard = ({ loading, loaded, history, token }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		loading();
		axios
			.get("/api/article/fetch", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			})
			.then(res => {
				setData(res.data);
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const truncateText = text => (text && text.length > 40 ? text.substr(0, 40) + "..." : text);

	const handleActive = id => {
		axios
			.post(
				"/api/admin/setting/article/show",
				{ id },
				{
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			)
			.then(() => {
				setData(
					data.map(x => {
						if (x.id !== id) {
							return x;
						} else {
							return {
								...x,
								active: !x.active
							};
						}
					})
				);
			})
			.catch(err => alert(err.response.data.error));
	};

	const tableData = useMemo(() => {
		return {
			columns: articleDashboardColumns,
			rows: data.map(article => {
				return {
					...article,
					image: <img src={article.image} alt="article" className="img-fluid" style={{ height: "80px", width: "auto" }} />,
					title: truncateText(article.title.en),
					titleBM: truncateText(article.title.bm),
					description: truncateText(article.description.en),
					descriptionBM: truncateText(article.description.bm),
					active: (
						<input
							type="checkbox"
							checked={article.active}
							value={article.active}
							onChange={() => handleActive(article.id)}
							className="form-check-input"
						/>
					),
					edit: (
						<button type="button" className="btn btn-info" onClick={() => history.push({ pathname: "/admin/article/edit", data: article })}>
							Edit
						</button>
					)
				};
			})
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Article Dashboard</h3>

					<button type="button" className="mt-3 btn btn-primary" onClick={() => history.push("/admin/article/add")}>
						<MdAddCircleOutline className="me-2" />
						Add Article
					</button>

					<div className="mt-5 card">
						<div className="card-header">
							<h5 className="card-title">Article</h5>
						</div>

						<div className="card-body text-center">
							<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		token: state.userReducer.token
	};
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDashboard);
