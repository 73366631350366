import { useState } from "react";
import Header from "../../component/dashboard/Header";
import Footer from "../../component/dashboard/Footer";
import TesseractCam from "../../component/scanCode/TesseractCam";
import VerifyCode from "../../component/scanCode/VerifyCode";
import ContentHeader from "../../component/dashboard/ContentHeader";
import { useTranslation } from "react-i18next";

const ScanCode = ({ history, location }) => {
	const { t } = useTranslation();
	const [isScanning, setScanning] = useState(location.isDesktop ? false : true);
	const [code, setCode] = useState("");
	return (
		<div>
			<Header />
			<div className="content" style={{ paddingBottom: "150px" }}>
				<ContentHeader title={t("scanuniquecode")} />
				<div>
					{isScanning ? (
						<TesseractCam setCode={setCode} setScanning={setScanning} code={code} />
					) : (
						<VerifyCode code={code} setCode={setCode} history={history} />
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default ScanCode;
