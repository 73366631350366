import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import DatePicker from "react-datepicker";
import axios from "axios";
import Loader from "../../component/Loader";
import Select from "react-select";
// import areaData from "../../config/areaData.json";
// import { states, postcodes, cities } from "../../config/dataStandardize";
// Components
import Header from "../../component/dashboard/Header";
import Footer from "../../component/dashboard/Footer";
import News from "../../component/dashboard/News";
import ItemPreview from "../../component/dashboard/ItemPreview";
import Goodies from "../../component/dashboard/Goodies";
import ContentHeader from "../../component/dashboard/ContentHeader";
import NeverBe from "../../component/dashboard/NeverBe";
// Assets
// import faces from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-dbc-forum.png";
import questDesktop from "../../assets/questDesktop.png";
import questMobile from "../../assets/questMobile.png";
import questUser from "../../assets/questUser.png";
import questCoin from "../../assets/questCoin.png";
import questAward from "../../assets/questAward.png";
import trophy from "../../assets/contestTrophy.png";
//Redux
import { connect } from "react-redux";
import {
	checkSession,
	alreadyLoggedIn,
	forceEditProfile,
	forceAddAddress,
	getBrandInfo,
	forceAddBabyInfo,
	getJourney,
	getQuest,
	setModalSeen,
	displaySSO
} from "../../redux/actions/userActions";
import { useTranslation } from "react-i18next";
import Gold from "../../assets/GoldDiapers.svg";
import Platinum from "../../assets/PlatinumDiapers.svg";
import Diamond from "../../assets/DiamondDiapers.svg";
import tierArrow from "../../assets/tierArrow.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import uploadReceiptIcon from "../../assets/Group 22060.png";

const Dashboard = ({
	user,
	checkSession,
	history,
	justLoggedIn,
	alreadyLoggedIn,
	location,
	forceEditProfile,
	forceAddAddress,
	getBrandInfo,
	brandData,
	forceAddBabyInfo,
	getJourney,
	journey,
	sample,
	quest,
	getQuest,
	language,
	id,
	showQuest,
	setModalSeen,
	displaySSO
}) => {
	const { t } = useTranslation();
	const history2 = useHistory();
	const [showModal, setShow] = useState(false);
	// const [showQuest, setShowQuest] = useState(false);
	const [selected, setSelected] = useState("");
	const [step, setStep] = useState(0);
	// const [journeyPoint, setJourneyPoint] = useState(0);
	// const [progressbarPercent, setProgressbarPercent] = useState(0);
	const [feedbackModal, setFeedbackModal] = useState(false);
	const [acknowledge, setAcknowledge] = useState(false);

	const userReset = user.tierResetDate;
	const dateComponents = userReset.split("-");
	const userResetFormatted = `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`;

	// useEffect(() => {
	// 	if (!user.allowRequest && user.promptFeedback) setFeedbackModal(true);
	// 	else if (user.promptFeedback) setFeedbackModal(true);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);
	const [ssoModal, setSsoModal] = useState(false);

	useEffect(() => {
		const ssoModalPopup = user.ssoPopup;

		if (ssoModalPopup === true) {
			setSsoModal(true);
		}
	}, [user.ssoPopup]);

	const closeSsoPopup = () => {
		const sendThis = {
			id: user.id
		};
		displaySSO(sendThis);
		setSsoModal(false);
	};

	useEffect(() => {
		if (!user.allowRequest && user.promptFeedback) setFeedbackModal(true);
		else if (user.promptFeedback) setFeedbackModal(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const closeFeedback = () => {
		setFeedbackModal(false);
		setAcknowledge(false);
	};

	const handleAnswer = received => history.push({ pathname: "/freesample/feedback", received });

	const [data, setData] = useState({
		name: user.name,
		race: user.race,
		nationality: user.nationality,
		pregnancyStage: user.pregnancyStage,
		edb: false,
		line1: "",
		// line2: "",
		// line3: "",
		city: "",
		state: "",
		postcode: "",
		country: "Malaysia",
		label: ""
	});

	const [babyData, setBabyData] = useState({
		// pregnancyStage: "",
		birthDate: new Date(),
		gender: "",
		brandId: "",
		formatId: "",
		sizeId: ""
	});

	// const [states, setStates] = useState([]);
	const [postcodes, setPostcodes] = useState([]);
	const [cities, setCities] = useState([]);
	const [dropdown, setDropdown] = useState({
		states: [],
		postcodes: [],
		cities: []
	});
	const [areaData, setAreaData] = useState([]);
	const today = new Date();
	const minDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);

	useEffect(() => {
		if (!quest) getQuest({ id });
	}, []);

	useEffect(() => {
		if (showQuest === false) {
			if (!user.allowRequest && user.promptFeedback) {
				setFeedbackModal(true);
			} else if (user.promptFeedback) {
				setFeedbackModal(true);
			}
		}
	}, []);

	useEffect(() => {
		const hasSeenModal = localStorage.getItem("hasSeenModal");
		if (!hasSeenModal) {
			localStorage.setItem("hasSeenModal", "true");
		}
	}, []);

	const closeQuest = () => {
		setModalSeen();

		if (!user.allowRequest && user.promptFeedback) {
			setFeedbackModal(true);
		} else if (user.promptFeedback) {
			setFeedbackModal(true);
		}
	};

	const highTierPoints = user.tierSetting[0].highTierPoints;
	const tierPercentage = (user.totalPointsInPastYear / highTierPoints) * 100;
	const pointToGo = highTierPoints - user.totalPointsInPastYear + 1;
	const nextTier = user.tierSetting[0].nextTier;

	useEffect(() => {
		if (user && !user.forceUpdated) {
			setShow(true);
		}
	}, [user, user.forceUpdated]);

	useEffect(() => {
		if (!brandData) getBrandInfo();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!journey) getJourney();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	if(journey) {
	// 	const journeyPoint = journey[0].points;
	// 	const progressbarPercent = (journeyPoint / 200000) * 100;
	// 	setJourneyPoint(journeyPoint);
	// 	setProgressbarPercent(progressbarPercent);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [journey]);

	useEffect(() => {
		if (user && !user.forceUpdated) {
			axios
				.get("https://mystatic123.s3.ap-southeast-1.amazonaws.com/areaData.json")
				.then(res => {
					setAreaData(res.data);
				})
				.catch(console.error);
		}
	}, [user, user.forceUpdated]);

	useEffect(() => {
		if (user && !user.forceUpdated) {
			axios
				.get("https://mystatic123.s3.ap-southeast-1.amazonaws.com/dataStandardize.json")
				.then(res => {
					// console.log(res.data);
					setDropdown({
						states: res.data.states.sort((a, b) => {
							if (a.label > b.label) return 1;
							if (a.label < b.label) return -1;
							return 0;
						}),
						postcodes: res.data.postcodes,
						cities: res.data.cities
					});
					// setStates(res.data.states);
					setPostcodes(res.data.postcodes);
					setCities(res.data.cities);
				})
				.catch(console.error);
		}
	}, [user, user.forceUpdated]);

	const [inputError, setInputError] = useState({
		name: null
	});

	function handleClick() {
		history2.push("/wishingtree/");
	}

	// const [success, setSuccess] = useState(false);

	const nextPage = () => {
		setStep(step + 1);
	};

	const doublePage = () => {
		setStep(step + 2);
	};

	const tripplePage = () => {
		setStep(step + 3);
	};

	const prevPage = () => {
		setStep(step - 1);
	};

	const prevDoublePage = () => {
		setStep(step - 2);
	};

	const prevTripplePage = () => {
		setStep(step - 3);
	};

	const prevQuadPage = () => {
		setStep(step - 4);
	};

	const handleChange = e => {
		setData({ ...data, [e.target.name]: e.target.value });
		if (e.target.name === "name") {
			const nameRegex = /^[a-zA-Z ]*$/;
			if (!nameRegex.test(e.target.value)) {
				setInputError({
					...inputError,
					name: "You have entered an invalid name"
				});
			} else {
				setInputError({
					...inputError,
					name: null
				});
			}
		}
	};

	const handleChangeBaby = e => {
		if (e.target.name === "brandId") {
			setBabyData({
				...babyData,
				brandId: e.target.value,
				formatId: "",
				sizeId: ""
			});
		} else if (e.target.name === "formatId") {
			setBabyData({
				...babyData,
				formatId: e.target.value,
				sizeId: ""
			});
		} else {
			setBabyData({ ...babyData, [e.target.name]: e.target.value });
		}
	};

	const handleChangeAddress = e => setData({ ...data, [e.target.name]: e.target.value });

	const handleMobileCode = num => {
		if (num && num.length > 8 && num[0].toString() !== "0") {
			return "+";
		} else {
			return "+6";
		}
	};

	const processAddress = (line1, line2, line3, city, state, postcode, country) => {
		let text = "";
		text += `${line1}, `;
		if (line2) text += `${line2}, `;
		if (line3) text += `${line3}, `;
		text += `${city}, `;
		text += `${state}, `;
		text += `${postcode}, `;
		text += `${country}`;
		return text;
	};

	useEffect(() => {
		if (user && user.addresses && user.addresses.length) {
			setSelected(user.addresses.filter(address => address.active && address.active === true)[0].id);
		} else {
			// setShow(true);
			// console.log("lol")
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// when user changed state
		let isMounted = true;
		if (data.state && isMounted) {
			const thisCities = areaData
				.filter(x => x.state === data.state)
				.map(x => x.city)
				.filter((x, y, z) => z.indexOf(x) === y);
			const filteredCities = cities.filter(x => thisCities.includes(x.value));

			if (filteredCities.length === 1) {
				isMounted &&
					setData({
						...data,
						city: filteredCities[0].value,
						postcode: ""
					});
			} else {
				isMounted &&
					setData({
						...data,
						city: "",
						postcode: ""
					});
			}
			isMounted &&
				setDropdown({
					...dropdown,
					cities: filteredCities
				});
		}

		return () => (isMounted = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.state]);

	useEffect(() => {
		// when user changed city
		let isMounted = true;
		if (data.city && isMounted) {
			const thisPostcodes = areaData
				.filter(x => x.state === data.state && x.city === data.city)
				.map(x => x.postcode)
				.filter((x, y, z) => z.indexOf(x) === y);
			const filteredPostcodes = postcodes.filter(x => thisPostcodes.includes(x.value));

			if (filteredPostcodes.length === 1) {
				isMounted &&
					setData({
						...data,
						postcode: filteredPostcodes[0].value
					});
			} else {
				isMounted &&
					setData({
						...data,
						postcode: ""
					});
			}
			isMounted &&
				setDropdown({
					...dropdown,
					postcodes: filteredPostcodes
				});
		}
		return () => (isMounted = false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data.city]);

	useEffect(() => {
		if (justLoggedIn) {
			window.dataLayer.push({
				userId: user.id,
				event: "login",
				gtm: {
					uniqueEventId: 18704
				},
				loginMethod: "email"
			});
			alreadyLoggedIn();
		}
		checkSession();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = e => {
		e.preventDefault();
		// Change from pregnancyStage to pregnant with edb
		if (
			// data.name !== user.name ||
			// data.race !== user.race ||
			// data.nationality !== user.nationality  &&
			data.pregnancyStage === "pregnant"
			// data.pregnancyStage !== user.pregnancyStage ||
			// data.edb !== user.edb
		) {
			// console.log("first");
			const sendThis = {
				id: user.id,
				name: data.name,
				race: data.race,
				nationality: data.nationality,
				pregnancyStage: data.pregnancyStage,
				edb: data.edb
			};
			forceEditProfile(sendThis);
			nextPage();
		} else {
			// console.log("second");
			const sendThis = {
				id: user.id,
				name: data.name,
				race: data.race,
				nationality: data.nationality,
				pregnancyStage: data.pregnancyStage,
				edb: null
			};
			forceEditProfile(sendThis);
			nextPage();
		}
	};

	const handleNext = e => {
		const { line1, line2, line3, city, state, postcode, country, label } = data;
		if (line1 && city && state && postcode && label) {
			const address = {
				line1,
				line2,
				line3,
				city,
				state,
				postcode,
				country,
				label
			};
			const { id } = user;
			const sendThis = {
				id,
				address
			};

			// push to different path //
			forceAddAddress(sendThis);
			prevPage();
		}
	};

	const handleBaby = e => {
		e.preventDefault();
		const { birthDate, gender, brandId, formatId, sizeId } = babyData;
		if (birthDate && gender && brandId && formatId && sizeId) {
			const sendThis = {
				id: user.id,
				babyInfo: babyData
			};
			forceAddBabyInfo(sendThis);
			prevPage();
		} else {
			alert("Please fill in all the fields");
		}
	};

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1
	};

	let points = user.totalPoints;
	let formattedTotalPoint = points.toLocaleString(); // This adds commas to the number

	return (
		<div id="user-dashboard">
			<Header />
			<div className="content">
				<ContentHeader title={`${t("welcome")}, ${user.name.split(" ")[0].toUpperCase()}!`} />

				{/* inbox/setting */}
				<div>
					<div className="dashboardProfileContainer">
						<Link className="dashboardProfilePicContainer" to="/userprofile">
							<img
								className="dashboardProfilePic img-fluid"
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/Frame-12.png"
								alt="pfpic"
							></img>
						</Link>

						<div className="dashboardProfileContent">
							<div className="dashboardProfile" style={{ display: "contents" }}>
								<div style={{ display: "flex" }}>
									<div className="user-profile-container">
										<h3 id="username" className="username mobile">
											{user.name.toUpperCase()}
										</h3>
										<h2 id="username" className="username desktop">
											{t("welcome")}, {user.name.split(" ")[0].toUpperCase()}!
										</h2>
										{/* <Link to="/tier">
								<h3 id="user-tier" className="username">
									{user.tier.toUpperCase()} TIER
								</h3>
								</Link> */}
										{/* <p className="user-milestone">100 MORE POINTS TO PLATINUM</p> */}
										<div className="desktop-user-info">
											<img
												src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star.png"
												alt="point star"
												className="img-fluid"
											/>
											<h3>{user.name}</h3>
											<div className="dot-separator"></div>
											{/* <h3>{user.tier.toUpperCase()}</h3> */}
										</div>
									</div>

									<Link className="user-point-container" to="/pointhistory" style={{ textDecoration: "none" }}>
										<p style={{ textAlign: "right" }} className="user-point-label">
											{t("yourpoints")}
										</p>
										<h4 id="user-point">{formattedTotalPoint}</h4>
									</Link>
								</div>

								<div className="tier">
									<div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
										<Link to="/tier" style={{ textDecoration: "none" }}>
											<div style={{ display: "flex" }}>
												<img
													src={user.tier === "Diamond" ? Diamond : user.tier === "Platinum" ? Platinum : Gold}
													style={{ width: "25px", marginRight: "5px" }}
												/>
												<div className="tier-title" style={{ marginRight: "10px", marginTop: "5px" }}>
													{user.tier}
												</div>
												<img style={{ marginTop: "10px", height: "10px" }} src={tierArrow} />
											</div>
										</Link>

										{/* <Link to="/tier" style={{ display: "flex", alignItems: "center", textDecoration: "none" }}>
								<img src={nextTier === "Diamond" ? Diamond : nextTier === "Platinum" ? Platinum : Gold} style={{ width: "25px" }} />
								<div className="tier-title" style={{ marginLeft: "5px" }}>
								{nextTier === "Diamond" ? "Diamond" : nextTier === "Platinum" ? "Platinum" : "Gold"}
								</div>
							</Link> */}
									</div>
									<ProgressBar now={user.tier === "Diamond" ? 100 : tierPercentage} />
									{user.tier !== "Diamond" ? (
										<div class="upgrade-date-text" style={{ textAlign: "initial", marginTop: "10px" }}>
											{pointToGo} {t("pointReach")} {nextTier} {t("tierBy")} {userResetFormatted}{" "}
										</div>
									) : (
										<div class="upgrade-date-text" style={{ textAlign: "initial", marginTop: "10px" }}>
											{t("tierHighest")}
											{userResetFormatted}
										</div>
									)}
								</div>
							</div>

							<div id="desktop-menu" className="dashboard-menu-container">
								<Link to={{ pathname: "/scancode", isDesktop: true }} style={{ textDecoration: "none" }}>
									<div className="dashboard-menu-item">
										<p className="dashboard-menu-title">{t("keyinunique")}</p>
										<img
											src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-scan-code.png"
											alt="menu icon"
											className="img-fluid dashboard-menu-icon"
										/>
									</div>
								</Link>
								<Link to="/uploadReceipt" style={{ textDecoration: "none" }}>
									<div className="dashboard-menu-item">
										<p className="dashboard-menu-title">{t("bbcReceipt")}</p>
										<img
											src={uploadReceiptIcon}
											alt="menu icon"
											className="img-fluid dashboard-menu-icon fitathon-image"
										/>
									</div>
								</Link>
								<Link to="/pointshop" style={{ textDecoration: "none" }}>
									<div className="dashboard-menu-item">
										<p className="dashboard-menu-title">{t("pointshop")}</p>
										<img
											src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/PointShop.png"
											alt="menu icon"
											className="img-fluid dashboard-menu-icon"
										/>
									</div>
								</Link>
								<Link to="/picksample" style={{ textDecoration: "none" }}>
									<div className="dashboard-menu-item">
										<p className="dashboard-menu-title">{t("freesample")}</p>
										<img
											src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-free-sample.png"
											alt="menu icon"
											className="img-fluid dashboard-menu-icon"
										/>
									</div>
								</Link>

								<Link to="/help" style={{ textDecoration: "none" }}>
									<div className="dashboard-menu-item">
										<p className="dashboard-menu-title">{t("helpcenter")}</p>
										<img
											src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-help.png"
											alt="menu icon"
											className="img-fluid dashboard-menu-icon"
										/>
									</div>
								</Link>

								<Link to="/quest" style={{ textDecoration: "none" }}>
									<div className="dashboard-menu-item">
										<p className="dashboard-menu-title">{t("myquest")}</p>
										<img
											src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-my-quest.png"
											alt="menu icon"
											className="img-fluid dashboard-menu-icon"
										/>
									</div>
								</Link>

								{/* //<Link to="/contest" style={{ textDecoration: "none" }}>
							//	<div className="dashboard-menu-item">
							//		<p className="dashboard-menu-title">{t("contestCap")}</p>
							//		<img src={trophy}
							//		alt="menu icon" 
							//		className="img-fluid dashboard-menu-icon" 
							//		/>
							//	</div>
							//</Link> */}
							</div>
						</div>
					</div>
				</div>

				<div id="mobile-menu" className="dashboard-menu-container">
					<Link to="/uploadReceipt" style={{ textDecoration: "none" }}>
						<div className="dashboard-menu-item">
							<p className="dashboard-menu-title">{t("bbcReceipt")}</p>
							<img src={uploadReceiptIcon} alt="menu icon" className="img-fluid dashboard-menu-icon fitathon-image" />
						</div>
					</Link>
					<Link to="/quest" style={{ textDecoration: "none" }}>
						<div className="dashboard-menu-item">
							<p className="dashboard-menu-title">{t("myquest")}</p>
							<img
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-my-quest.png"
								alt="menu icon"
								className="img-fluid dashboard-menu-icon"
							/>
						</div>
					</Link>
					<Link to="/pointshop" style={{ textDecoration: "none" }}>
						<div className="dashboard-menu-item">
							<p className="dashboard-menu-title">{t("pointshop")}</p>
							<img
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/PointShop.png"
								alt="menu icon"
								className="img-fluid dashboard-menu-icon"
							/>
						</div>
					</Link>
					<Link to="/picksample" style={{ textDecoration: "none" }}>
						<div className="dashboard-menu-item">
							<p className="dashboard-menu-title">{t("freesample")}</p>
							<img
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-free-sample.png"
								alt="menu icon"
								className="img-fluid dashboard-menu-icon"
							/>
						</div>
					</Link>
					<Link to="/help" style={{ textDecoration: "none" }}>
						<div className="dashboard-menu-item">
							<p className="dashboard-menu-title">{t("helpcenter")}</p>
							<img
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-help.png"
								alt="menu icon"
								className="img-fluid dashboard-menu-icon"
							/>
						</div>
					</Link>

					<Link to="/contest" style={{ textDecoration: "none" }}>
						<div className="dashboard-menu-item">
							<p className="dashboard-menu-title">{t("contestCap")}</p>
							<img src={trophy} alt="menu icon" className="img-fluid dashboard-menu-icon" />
						</div>
					</Link>
				</div>

				{/* <div onClick={handleClick} className="mission-background" style={{padding: "25px", textAlign: "center", margin: "17px"}}>
						<h1 style={{color: "white", fontSize: "18px"}} className="dbc-title mb-2 mt-2">WISHING TREE MISSION</h1>
						 <ProgressBar now={progressbarPercent} visuallyHidden />
						 <p style={{color: "white", fontSize: "8px"}} className="mt-1"><strong>{journeyPoint}</strong> donated from 200,000 points</p>
					</div> */}
				<News />

				<ItemPreview history={history} title={t("withyourpoints")} />

				<Goodies />

				<NeverBe />

				<Footer />
			</div>

			<Modal show={showModal} dialogClassName="modal-90w" centered>
				<Modal.Body className="text-center">
					{step === 0 ? (
						<div>
							<h1 style={{ paddingLeft: "30px", paddingRight: "30px" }} className="dashboardNewsTitle">
								{t("updateDetails")}
							</h1>
							<h2
								style={{
									fontSize: "18px",
									marginBottom: "20px",
									paddingLeft: "10px",
									paddingRight: "10px",
									paddingTop: "20px"
								}}
								className="UpdateText"
							>
								{t("updateText1")}
							</h2>
							<h2
								style={{ fontSize: "18px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "30px" }}
								className="UpdateText"
							>
								{t("updateText2")}
							</h2>
							<div style={{ paddingBottom: "20px" }} className="form-group vinda-form-group text-center">
								<Loader
									component={
										<input
											value={t("updateNow")}
											onClick={nextPage}
											className="FillBtn"
											style={{ cursor: "pointer" }}
										/>
									}
								/>
							</div>
						</div>
					) : step === 1 ? (
						<div>
							<h1 className="dashboardNewsTitle">{t("updatePersonalInfo")}</h1>

							<form
								className="vinda-form"
								// onSubmit={handleSubmit}
								style={{ paddingLeft: "20px", paddingRight: "20px", width: "100% !important" }}
							>
								<div className="form-group vinda-form-group">
									<label htmlFor="name" className="vinda-form-label">
										{t("name")}
									</label>
									<input
										type="text"
										name="name"
										id="name"
										className="form-control vinda-form-input fw-bold no-border"
										placeholder={t("fullname")}
										value={data.name}
										onChange={handleChange}
										required
									/>
									{inputError.name ? <span style={{ color: "red" }}>{inputError.name}</span> : null}
								</div>

								<div className="form-group vinda-form-group">
									<label htmlFor="number" className="vinda-form-label">
										{t("phonenumber")}
									</label>

									<div className="input-group">
										<span className="input-group-text no-border fw-bold">{handleMobileCode(user.number)}</span>
										<input
											type="text"
											name="number"
											id="number"
											className="form-control vinda-form-input fw-bold no-border"
											placeholder={t("phonenumber")}
											value={user.number}
											readOnly
											disabled
											required
										/>
									</div>
								</div>

								<div className="form-group vinda-form-group">
									<label htmlFor="email" className="vinda-form-label">
										{t("email")}
									</label>
									<input
										type="email"
										name="email"
										id="email"
										className="form-control vinda-form-input no-border fw-bold"
										placeholder={t("emailaddress")}
										value={user.email}
										readOnly
										disabled
										required
									/>
								</div>

								<div className="form-group vinda-form-group">
									<label htmlFor="race" className="vinda-form-label">
										{t("race")}
									</label>
									<select
										name="race"
										id="race"
										className="form-select vinda-form-input no-border fw-bold"
										value={data.race}
										onChange={handleChange}
										required
									>
										<option disabled value="">
											{t("race")}
										</option>
										<option value="malay">Malay</option>
										<option value="chinese">Chinese</option>
										<option value="indian">Indian</option>
										<option value="other">{t("others")}</option>
									</select>
								</div>

								<div className="form-group vinda-form-group">
									<label htmlFor="email" className="vinda-form-label">
										{t("nationality")}
									</label>
									<select
										name="nationality"
										id="nationality"
										className="form-select vinda-form-input no-border fw-bold"
										value={data.nationality}
										onChange={handleChange}
										required
									>
										<option disabled value="">
											Nationality
										</option>
										<option value="malaysian">Malaysian</option>
										<option value="non-malaysian">Non-Malaysian</option>
									</select>
								</div>

								<div className="form-group vinda-form-group">
									<label className="vinda-form-label" htmlFor="pregnancyStage">
										{t("currentstage")}
									</label>

									<select
										id="pregnancyStage"
										name="pregnancyStage"
										className="form-select vinda-form-input no-border fw-bold"
										value={data.pregnancyStage}
										onChange={handleChange}
										required
									>
										<option disabled value="">
											{t("currentstage")}
										</option>
										<option value="trying">{t("trying")}</option>
										<option value="pregnant">{t("pregnant")}</option>
										<option value="oneChild">{t("oneChild")}</option>
										<option value="moreChild">{t("moreChild")}</option>
									</select>
								</div>

								<div className="form-group vinda-form-group text-center">
									<Loader
										component={
											<input
												onClick={
													data.pregnancyStage === "pregnant" ||
													data.pregnancyStage === "oneChild" ||
													data.pregnancyStage === "moreChild"
														? nextPage
														: tripplePage
												}
												value={t("nextButton")}
												className="vinda-form-submit btn"
												style={{ cursor: "pointer" }}
											/>
										}
									/>
								</div>
							</form>
						</div>
					) : step === 2 ? (
						<div>
							{data.pregnancyStage === "pregnant" ? (
								<div>
									<h1 className="dashboardNewsTitle">{t("edb")}</h1>
									<form className="vinda-form" style={{ paddingBottom: "15px", padding: "0px" }}>
										<div className="form-group vinda-form-group">
											<label htmlFor="edb" className="vinda-form-label">
												{t("whenEdb")}
											</label>
											<DatePicker
												selected={data.edb}
												onChange={date => setData({ ...data, edb: date })}
												id="edb"
												name="edb"
												//   className="form-control vinda-form-input"
												className="form-select vinda-form-input no-border fw-bold"
												placeholderText={t("edb")}
												showYearDropdown
												showMonthDropdown
												dropdownMode="select"
												dateFormat="dd/MM/yyyy"
												minDate={minDate}
												required
											/>
											<div className="form-group vinda-form-group text-center">
												<Loader
													component={
														<input onClick={doublePage} value={t("nextButton")} className="vinda-form-submit btn" />
													}
												/>
											</div>
											<div className="form-group vinda-form-group text-center">
												<Loader
													component={
														<input onClick={prevPage} value={t("backButton")} className="vinda-form-back btn" />
													}
												/>
											</div>
										</div>
									</form>
								</div>
							) : data.pregnancyStage === "oneChild" || data.pregnancyStage === "moreChild" ? (
								<div>
									<form className="vinda-form" style={{ padding: "0" }}>
										<div className="text-center">
											<h1 className="dbc-title">{t("babyinfo")}</h1>
										</div>
										<div className="pickAddressHolder">
											{user && user.babyInfos && user.babyInfos.length
												? user.babyInfos.map(baby => (
														<div key={baby.id} className="pickAddressContainer">
															<div className="p-2">
																<h5 className="m-0 p-0">{baby.name || "-"}</h5>
																<h6 className="m-0 p-0">{baby.gender ? baby.gender.toUpperCase() : "-"}</h6>
																<h6 className="m-0 p-0">{new Date(baby.birthDate).toLocaleDateString("en-GB")}</h6>
															</div>
														</div>
												  ))
												: null}

											<div style={{ textAllign: "center" }}>
												<p onClick={nextPage} style={{ textAllign: "center" }} className="addToCartText fw-bold">
													+ {t("addchild")}
												</p>
											</div>
										</div>

										<div className="form-group vinda-form-group text-center">
											<Loader
												component={
													<input
														onClick={doublePage}
														value={t("nextButton")}
														className="vinda-form-submit btn"
														type="submit"
													/>
												}
											/>
										</div>

										<div className="form-group vinda-form-group text-center">
											<Loader
												component={<input onClick={prevPage} value={t("backButton")} className="vinda-form-back btn" />}
											/>
										</div>
									</form>
								</div>
							) : null}
						</div>
					) : step === 3 ? (
						<div>
							<h1 className="dashboardNewsTitle">{t("babyinfo")}</h1>
							<br></br>
							<form className="vinda-form" style={{ padding: 0 }}>
								<label htmlFor="birthDate" className="vinda-form-label">
									Baby Information 1
								</label>
								<div className="form-group vinda-form-group">
									<label htmlFor="birthDate" className="vinda-form-label">
										{t("dobchild")}
									</label>
									<DatePicker
										selected={babyData.birthDate}
										onChange={date => setBabyData({ ...babyData, birthDate: date })}
										id="birthDate"
										name="birthDate"
										className="form-control vinda-form-input no-border fw-bold"
										placeholderText={t("date")}
										showYearDropdown
										showMonthDropdown
										dropdownMode="select"
										dateFormat="dd/MM/yyyy"
										required
									/>
								</div>

								<div className="form-group vinda-form-group">
									<label htmlFor="gender" className="vinda-form-label">
										{t("childgender")}
									</label>
									<select
										name="gender"
										id="gender"
										className="form-select vinda-form-input no-border fw-bold"
										value={babyData.gender}
										onChange={handleChangeBaby}
										required
									>
										<option disabled value="">
											{t("gender")}
										</option>
										<option value="male">{t("male")}</option>
										<option value="female">{t("female")}</option>
									</select>
								</div>

								<div className="form-group vinda-form-group">
									<label htmlFor="brandId" className="vinda-form-label">
										{t("babydiaper")}
									</label>
									<select
										name="brandId"
										id="brandId"
										className="form-select vinda-form-input no-border fw-bold"
										value={babyData.brandId}
										onChange={handleChangeBaby}
										required
									>
										<option disabled value="">
											{t("brand")}
										</option>
										{brandData && brandData.length
											? brandData.map(brand => (
													<option key={brand.id} value={brand.id}>
														{brand.name}
													</option>
											  ))
											: null}
									</select>
								</div>

								<div className="form-group vinda-form-group">
									<label htmlFor="formatId" className="vinda-form-label">
										{t("diaperformat")}
									</label>
									<select
										name="formatId"
										id="formatId"
										className="form-select vinda-form-input no-border fw-bold"
										value={babyData.formatId}
										onChange={handleChangeBaby}
										required
									>
										<option disabled value="">
											{t("format")}
										</option>

										{brandData && brandData.length
											? brandData.filter(brand => brand.id === babyData.brandId)[0]
												? brandData
														.filter(brand => brand.id === babyData.brandId)[0]
														.formats.map(format => (
															<option key={format.id} value={format.id}>
																{format.name.toUpperCase()}
															</option>
														))
												: null
											: null}
									</select>
								</div>

								<div className="form-group vinda-form-group">
									<label htmlFor="sizeId" className="vinda-form-label">
										{t("diapersize")}
									</label>
									<select
										name="sizeId"
										id="sizeId"
										className="form-select vinda-form-input no-border fw-bold"
										value={babyData.sizeId}
										onChange={handleChangeBaby}
										required
									>
										<option disabled value="">
											{t("size")}
										</option>
										{brandData && brandData.length
											? brandData.filter(brand => brand.id === babyData.brandId)[0]
												? brandData
														.filter(brand => brand.id === babyData.brandId)[0]
														.sizes.filter(
															size => size.brandId === babyData.brandId && size.formatId === babyData.formatId
														)
														.map(size => (
															<option key={size.id} value={size.id}>
																{size.name.toUpperCase()} ({size.description})
															</option>
														))
												: null
											: null}
									</select>
								</div>

								<div className="form-group vinda-form-group text-center">
									<Loader
										component={
											<input
												onClick={handleBaby}
												type="submit"
												value={t("nextButton")}
												className="vinda-form-submit btn"
											/>
										}
									/>
								</div>
								<div className="form-group vinda-form-group text-center">
									<Loader
										component={
											<input onClick={prevPage} type="submit" value={t("backButton")} className="vinda-form-back btn" />
										}
									/>
								</div>
							</form>
						</div>
					) : step === 4 ? (
						<div>
							<form className="vinda-form" style={{ paddingBottom: "15px", padding: "0px" }}>
								<h1 className="dashboardNewsTitle">{t("address")}</h1>
								<div className="pickAddressHolder">
									{user && user.addresses && user.addresses.length
										? user.addresses
												.filter(address => address.active && address.active === true)
												.map(address => (
													<div
														key={address.id}
														className="pickAddressContainer"
														onClick={() => setSelected(address.id)}
													>
														<div className="pickAddressRadioButtonContainer">
															{location.type === "order" || location.type === "sample" ? (
																<input
																	type="radio"
																	name="address"
																	className="form-check-input"
																	checked={selected === address.id}
																	readOnly
																/>
															) : null}
														</div>

														<div className="p-2">
															<h5 className="m-0 p-0">{address.label ? address.label.toUpperCase() : "HOME"}</h5>
															<h6 className="m-0 p-0">
																{user.name} ({handleMobileCode(user.number)}
																{user.number})
															</h6>
															<p className="m-0 p-0">
																{processAddress(
																	address.line1,
																	address.line2,
																	address.line3,
																	address.city,
																	address.state,
																	address.postcode,
																	address.country
																)}
															</p>
														</div>
													</div>
												))
										: null}

									<div style={{ textAllign: "center" }}>
										<p onClick={nextPage} style={{ textAllign: "center" }} className="addToCartText fw-bold">
											+ {t("addaddress")}
										</p>
										{/* </Link> */}
									</div>
									<div className="form-group vinda-form-group text-center">
										<Loader
											component={
												<input onClick={doublePage} value={t("nextButton")} className="vinda-form-submit btn" />
											}
										/>
									</div>
									<div className="form-group vinda-form-group text-center">
										<Loader
											component={
												<input
													onClick={
														data.pregnancyStage === "pregnant"
															? prevTripplePage
															: data.pregnancyStage === "oneChild" || "moreChild"
															? prevTripplePage
															: prevQuadPage
													}
													value={t("backButton")}
													className="vinda-form-back btn"
												/>
											}
										/>
									</div>
								</div>
							</form>
						</div>
					) : step === 5 ? (
						<div>
							<form className="vinda-form" style={{ padding: "0px" }}>
								<div className="form-group vinda-form-group">
									<label htmlFor="address" className="vinda-form-label">
										{t("address")}
									</label>
									<textarea
										name="line1"
										id="line1"
										className="form-control vinda-form-input"
										placeholder={t("address")}
										value={data.line1}
										onChange={handleChangeAddress}
										required
									></textarea>
								</div>

								<div className="row">
									<div className="form-group vinda-form-group col me-1">
										<label htmlFor="state" className="vinda-form-label">
											{t("state")}
										</label>
										<Select
											options={dropdown.states}
											onChange={e => setData({ ...data, state: e.value })}
											placeholder={t("state")}
											value={dropdown.states.filter(x => x.value === data.state)}
											required
										/>
									</div>
									<div className="form-group vinda-form-group col ms-1">
										<label htmlFor="city" className="vinda-form-label">
											{t("city")}
										</label>
										<Select
											options={dropdown.cities}
											onChange={e => setData({ ...data, city: e.value })}
											placeholder={t("city")}
											value={dropdown.cities.filter(x => x.value === data.city)}
											isDisabled={!data.state}
											required
										/>
									</div>
								</div>

								<div className="row">
									<div className="form-group vinda-form-group col me-1">
										<label htmlFor="postcode" className="vinda-form-label">
											{t("postcode")}
										</label>
										<Select
											options={dropdown.postcodes}
											onChange={e => setData({ ...data, postcode: e.value })}
											placeholder={t("postcode")}
											value={dropdown.postcodes.filter(x => x.value === data.postcode)}
											isDisabled={!data.state || !data.city}
											required
										/>
									</div>
									<div className="form-group vinda-form-group col ms-1">
										<label htmlFor="country" className="vinda-form-label">
											{t("country")}
										</label>
										<input
											type="text"
											name="country"
											id="country"
											className="form-control vinda-form-input"
											placeholder={t("country")}
											value={data.country}
											disabled
											readOnly
											required
										/>
									</div>
								</div>

								<div className="form-group vinda-form-group">
									<label htmlFor="label" className="vinda-form-label">
										{t("label")}
									</label>
									<input
										type="text"
										name="label"
										id="label"
										className="form-control vinda-form-input"
										placeholder={t("labellong")}
										value={data.label}
										onChange={handleChangeAddress}
										required
									/>
								</div>

								<div className="form-group vinda-form-group text-center">
									<Loader
										component={
											<input
												// onClick={nextPage}
												onClick={handleNext}
												type="submit"
												value={t("nextButton")}
												className="vinda-form-submit btn"
											/>
										}
									/>
								</div>
								<div className="form-group vinda-form-group text-center">
									<Loader
										component={
											<input onClick={prevPage} type="submit" value={t("backButton")} className="vinda-form-back btn" />
										}
									/>
								</div>
							</form>
						</div>
					) : step === 6 ? (
						<div>
							<form className="vinda-form" style={{ paddingBottom: "15px", padding: "0px" }}>
								<img
									src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/blueface.png"
									className="img-fluid smiley"
									alt="smiley face"
								/>

								<h1 className="dashboardNewsTitle">{t("confirmUpdate")}</h1>
								<h2 style={{ fontSize: "18px", marginBottom: "10px" }} className="UpdateText">
									{t("confirmDescription")}
								</h2>
								<div className="form-group vinda-form-group text-center">
									<Loader
										component={
											<input
												onClick={handleSubmit}
												value={t("yesButton")}
												className="vinda-form-submit btn"
												type="submit"
											/>
										}
									/>
								</div>
								<div className="form-group vinda-form-group text-center">
									<Loader
										component={
											<input onClick={prevDoublePage} value={t("backButton")} className="vinda-form-back btn" />
										}
									/>
								</div>
							</form>
						</div>
					) : step === 7 ? (
						<div>
							<form className="vinda-form" style={{ paddingBottom: "15px", padding: "0px" }}>
								<img
									src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png"
									className="img-fluid smiley"
									alt="smiley face"
								/>
								<h1 className="dashboardNewsTitle">{t("updated")}</h1>
								<h2 style={{ fontSize: "18px", marginBottom: "10px" }} className="UpdateText">
									{t("successfulUpdate")}
								</h2>
								<div className="form-group vinda-form-group text-center">
									<Loader
										component={
											<input onClick={() => setShow(false)} value={t("okayButton")} className="vinda-form-submit btn" />
										}
									/>
								</div>
							</form>
						</div>
					) : null}
				</Modal.Body>
			</Modal>
			<Modal centered show={feedbackModal} onHide={closeFeedback}>
				<Modal.Body>
					{!acknowledge ? (
						<div className="text-center">
							<img
								className="img-fluid text-center"
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/asset-12-3.png"
								alt="love"
							></img>
							<br />
							<h1 className="addToCartTitle">{t("yourfeedback")}</h1>
							<p className="pickSampleModalText">{t("itseems")}</p>
							<div>
								<button className="pickAddressConfirmButton" onClick={() => setAcknowledge(true)}>
									{t("sharefeedback")}
								</button>
							</div>
						</div>
					) : (
						<div className="text-center">
							<img
								style={{ width: "40%" }}
								src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/Group-22075.png"
								alt="askimg"
							></img>
							<h6 className="addToCartTitle">{t("haveyoureceived")}</h6>
							<Button
								style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }}
								onClick={() => handleAnswer(true)}
							>
								{t("nextButton")}
							</Button>
							{/* <Button
								style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }}
								onClick={() => handleAnswer(false)}
							>
								{t("no")}
							</Button> */}
						</div>
					)}
				</Modal.Body>
			</Modal>

			<Modal show={showQuest} dialogClassName="modal-90w" centered onHide={closeQuest}>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body className="text-center">
					<Slider {...settings} classname="modal-slider">
						<div>
							<img style={{ margin: "auto", paddingTop: "30px" }} src={questDesktop} />
							<h6 className="QuestSubText mt-3">{t("questPopupTitle1")}</h6>
							<h6 className="QuestSubText">
								<span style={{ fontFamily: "VAGRounded", color: "#0062BF" }}>{t("myQuest")}</span>{" "}
								{t("questPopupDesc1")}
							</h6>
						</div>
						<div>
							<img style={{ margin: "auto", paddingTop: "40px" }} src={questCoin} />
							<h6 style={{ padding: "20px" }} className="QuestSubText">
								{t("questPopupDesc2")}
							</h6>
						</div>
						<div>
							<strong className="yourCurrentQuest">{t("currentQuest")}:</strong>
							{quest && quest.length ? (
								quest.map(x => (
									<div key={x.id}>
										{x.quests.map((questItem, index) => (
											<div key={index}>
												{questItem.completed ? (
													<></>
												) : (
													<div>
														<div style={{ padding: "20px" }}>
															<div style={{ display: "flex", alignItems: "center" }}>
																<img style={{ marginRight: "10px" }} src={questUser} />
																<div>
																	<h6 className="questHeader">{questItem.title[language]}</h6>
																	<h6 className="questSubHeader">{questItem.description[language]}</h6>
																</div>
															</div>
														</div>
													</div>
												)}
											</div>
										))}
									</div>
								))
							) : (
								<></>
							)}
						</div>
					</Slider>
					<Link to="/quest" style={{ textDecoration: "none" }}>
						<div className="gotoQuest mt-5">{t("gotoQuest")}</div>
					</Link>
				</Modal.Body>
			</Modal>
			

			<Modal show={ssoModal} onHide={closeSsoPopup} dialogClassName="modal-90w" centered>
				<Modal.Body className="text-center">
					<div>
						<h1
							style={{ paddingLeft: "30px", paddingRight: "30px", marginTop: "100px" }}
							className="dashboardNewsTitle"
						>
							{t("ssoModalHead")}
						</h1>
						<h2
							style={{
								fontSize: "18px",
								marginBottom: "20px",
								paddingLeft: "10px",
								paddingRight: "10px",
								paddingTop: "20px"
							}}
							className="UpdateText"
						>
							{t("ssoModal1")}
						</h2>
						<button className="pickAddressConfirmButton" onClick={closeSsoPopup} style={{ marginBottom: "100px" }}>
							{t("closeButton")}
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		checkSession: () => dispatch(checkSession()),
		alreadyLoggedIn: () => dispatch(alreadyLoggedIn()),
		forceEditProfile: data => dispatch(forceEditProfile(data)),
		forceAddAddress: (data, callback) => dispatch(forceAddAddress(data, callback)),
		forceAddBabyInfo: (data, callback) => dispatch(forceAddBabyInfo(data, callback)),
		getBrandInfo: () => dispatch(getBrandInfo()),
		getJourney: data => dispatch(getJourney(data)),
		getQuest: data => dispatch(getQuest(data)),
		setModalSeen: () => dispatch(setModalSeen()),
		displaySSO: data => dispatch(displaySSO(data))
	};
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		popup: state.userReducer.popup,
		justLoggedIn: state.userReducer.justLoggedIn,
		language: state.pageReducer.language,
		brandData: state.userReducer.brand,
		journey: state.userReducer.journey,
		quest: state.userReducer.quest,
		id: state.userReducer.user.id,
		showQuest: state.userReducer.showQuest
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
