// import { useState } from "react";
import { connect } from "react-redux";
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
import { useTranslation } from "react-i18next";
import { useState, useRef } from "react";
import { BsUpload, BsFillCameraFill, BsFillCaretRightFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import Loader from "../../../component/Loader";
import loveFace from "../../../assets/contestLoveImage.png";
import smileFace from "../../../assets/contestSmileFace.png"

const Activity = ({ user, language, loading, loaded, location, history }) => {
	const { t } = useTranslation();
	const num = Array(location.state?.number).fill(null);
    const contest = location.state.contest;

	console.log(contest)

    //Upload Images
    const uploadScreenshot = useRef({});
    const [images, setImages] = useState(Array(location.state?.number).fill(""));
    const [uri, setUri] = useState(Array(location.state?.number).fill());
    // console.log(images);

    //Upload Receipts
    const uploadReceipts = useRef({});
    const [imagesReceipts, setImagesReceipts] = useState(Array(location.state?.number).fill(""));
    const [uriReceipts, setUriReceipts] = useState(Array(location.state?.number).fill());
    // console.log(imagesReceipts);

    //Link URL
    const postLink = useRef({});
    const [link, setLink] = useState(Array(location.state?.number).fill(""));
    // console.log(link);

    //Dropdown
	const [selectedValue, setSelectedValue] = useState(Array(location.state?.number).fill(""));
	const [selectFieldStyle, setSelectFieldStyle] = useState([]);
	const [selectArrowStyle, setSelectArrowStyle] = useState([]);
	// console.log(selectedValue);

    //Modal
	const [showModal, setShowModal] = useState(false);
	const [showModalTwo, setShowModalTwo] = useState(false);

    //Upload Images
    const handleUploadScreenshot = i => {
		uploadScreenshot.current[i].click();
	};

    const handleDisplayImage = (e, i) => {
		let render = new FileReader();

		if (e.target.files[0]) {
			let img = [...images];
			img[e.target.id.split("image")[1]] = e.target.files[0];
			setImages(img);

			render.readAsDataURL(e.target.files[0]);
			render.onload = res => {
				let data = [...uri];
				data[e.target.id.split("image")[1]] = res.target.result;
				setUri(data);
			};
		}
	};

    //Upload Receipts
    const handleUploadReceipts = i => {
		uploadReceipts.current[i].click();
	};

    const handleDisplayImageReceipts = (e, i) => {
		let render = new FileReader();

		if (e.target.files[0]) {
			let img = [...imagesReceipts];
			img[e.target.id.split("imageReceipts")[1]] = e.target.files[0];
			setImagesReceipts(img);

			render.readAsDataURL(e.target.files[0]);
			render.onload = res => {
				let data = [...uriReceipts];
				data[e.target.id.split("imageReceipts")[1]] = res.target.result;
				setUriReceipts(data);
			};
		}
	};

    //PostLink
    const handleLink = (e, i) => {
        if (e.target.value) {
          let img = [...link];
          img[e.target.id.split("link")[1]] = e.target.value;
          setLink(img);
        }
      };

	//Dropdown

	const expandSelectField = (i) => {
		let style = [...selectFieldStyle];
		let arrowstyle = [...selectArrowStyle];
	
		if (selectFieldStyle[i]?.maxHeight !== '700px') {
		  style[i] = { maxHeight: '700px', transition: '0.5s' };
		  setSelectFieldStyle(style);
		  arrowstyle[i] = { transform: 'rotate(90deg)', transition: '0.5s' };
		  setSelectArrowStyle(arrowstyle);
		} else {
		  style[i] = { maxHeight: '50px', transition: '0.3s' };
		  setSelectFieldStyle(style);
		  arrowstyle[i] = { transform: 'rotate(0deg)', transition: '0.3s' };
		  setSelectArrowStyle(arrowstyle);
		}
	  };


	const changeSelectedValue = (index, optionIndex) => {
		let value = [...selectedValue];
		value[index] = contest.dropdownOptions[optionIndex].dropdown;
		setSelectedValue(value);
	  };
	  
	  

	const handleSubmit = () => {
		setShowModal(false);
		let pass = true;
		const formData = new FormData();
		formData.append("id", user.id);
        formData.append("contestId", contest.id)
        num.forEach((x, i) => {
            // if (!images[i]) {
            //     pass = true;
            // }
            // else if (!imagesReceipts[i]) {
            //     pass = true;
            // }
            // else if (!link[i]) {
            //     pass = true;
            // }
            // else {
            //     pass = false;
            // }
                formData.append(`image${i}`, images[i]);
				formData.append(`receiptsImage${i}`, imagesReceipts[i]);
				formData.append(`link${i}`, link[i]);
				formData.append(`selectedValue${i}`, selectedValue[i]);
        });

		if (pass) {
			loading();
			axios
				.post("/api/contest/submit", formData)
				.then(() => setShowModalTwo(true))
				.catch(err => {
					alert(err.response.data.error[language]);
					loaded();
				});
			console.log(formData);
		} else {
			console.log("Missing data");
		}
	};


	return (
		<div>
			<Header />
			<div className="content">
				<ContentHeader title="Contest" />

				<div className="activity-header">
					<div className="activity-header-container">
						<img
							// src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/campaign/activity-one.png"
							src={contest.image[language]}
							alt="activity one"
							className="activity-header-image"
						/>
						{/* <h6 className="campaign-activity-desc">{t("activityOneDesc")}</h6> */}
					</div>
				</div>

				<form onSubmit={e => {e.preventDefault(); setShowModal(true);}}
				>
					<div className="activity-form-container">
						{num.map((data, index) => (
						<div>
                        {/* Upload Images */}
                        {contest.uploadImages === true ? (
                        <div>
                            <div className="activity-form-group">
                                <h5 className="activity-form-input-title-big">
								{t("uploadImageContest")} {index + 1} <span style={{ color: "red" }}>*</span>
								</h5>
                                <input
                                    type="file"
                                    ref={e => (uploadScreenshot.current[index] = e)}
                                    onChange={e => handleDisplayImage(e, index)}
                                    name="screenshot"
                                    className="form-control activity-form-input-file"
                                    id={"image" + index}
                                    accept="image/png, image/jpg, image/jpeg"
                                    required
                                />
                                {uri[index] ? (
                                    <div className="activity-upload-image-container" onClick={() => handleUploadScreenshot(index)}>
                                        <img src={uri[index]} alt="screenshot" className="activity-upload-image-preview" />
                                        <div className="activity-upload-image-cover">
                                            <img
                                                src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/campaign/camera.png"
                                                alt="camera"
                                                className="activity-upload-image-cover-camera"
                                            />
                                            <h6 className="activity-upload-image-cover-text">{t("picSuccessUpload")}</h6>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="activity-form-input-upload" onClick={() => handleUploadScreenshot(index)}>
                                        <BsFillCameraFill className="activity-form-input-upload-mobile" />
                                        <BsUpload className="activity-form-input-upload-desktop" />
                                    </div>
                                )}
                            </div>
                        </div>
                        ) : null}

                        {/* Upload Receipts */}
                        {contest.uploadReceipts === true ? (
                        <div>
                            <div className="activity-form-group">
                                <h5 className="activity-form-input-title-big">
								{t("uploadReceiptContest")} {index + 1} <span style={{ color: "red" }}>*</span>
								</h5>
                                <input
                                    type="file"
                                    ref={e => (uploadReceipts.current[index] = e)}
                                    onChange={e => handleDisplayImageReceipts(e, index)}
                                    name="screenshotReceipts"
                                    className="form-control activity-form-input-file"
                                    id={"imageReceipts" + index}
                                    accept="image/png, image/jpg, image/jpeg"
                                    required
                                />
                                {uriReceipts[index] ? (
                                    <div className="activity-upload-image-container" onClick={() => handleUploadReceipts(index)}>
                                        <img src={uriReceipts[index]} alt="screenshot" className="activity-upload-image-preview" />
                                        <div className="activity-upload-image-cover">
                                            <img
                                                src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/campaign/camera.png"
                                                alt="camera"
                                                className="activity-upload-image-cover-camera"
                                            />
                                            <h6 className="activity-upload-image-cover-text">{t("picSuccessUpload")}</h6>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="activity-form-input-upload" onClick={() => handleUploadReceipts(index)}>
                                        <BsFillCameraFill className="activity-form-input-upload-mobile" />
                                        <BsUpload className="activity-form-input-upload-desktop" />
                                    </div>
                                )}
                            </div>
                          </div>
                        ) : null}

                        {/* Post Link */}
                        {contest.linkURL === true ? (
                        <div>
                            <div className="activity-form-group">
                                <h5 className="activity-form-input-title-big">
								{t("submitLinkContest")} {index + 1} <span style={{ color: "red" }}>*</span>
								</h5>
                                <input
                                    type="text"
                                    ref={e => (postLink.current[index] = e)}
                                    onChange={e => handleLink(e, index)}
                                    name="link"
                                    className="form-control activity-form-input-text"
                                    id={"link" + index}
                                    required
                                />
                            </div>
                          </div>
                        ) : null}

						{/* Got Dropdown */}
						{contest.gotDropdown === true ? (
						<div>
						<div className="activity-form-group">
						<h5 className="activity-form-input-title-big">
							{contest.dropdownTitle[language]}
							</h5>
							<div style={{ position: 'relative', width: '80%', maxWidth: '400px' }}>
							<select
								name="size"
								className="form-select activity-form-input-select"
								value={selectedValue[index] ? selectedValue[index] : ''}
								required
							>
								<option value=""></option>
								{contest.dropdownOptions.map((option, optionIndex) => (
								<option key={option.id} value={option.dropdown} onClick={() => changeSelectedValue(index, optionIndex)}>
									{option.dropdown}
								</option>
								))}
							</select>
							<div
								className="activity-form-input-select-style"
								onClick={() => expandSelectField(index)}
								style={selectFieldStyle[index]}
							>
								<h4 style={{ minHeight: '30px', paddingRight: '30px', fontWeight: 'bold' }}>
								{selectedValue[index] ? selectedValue[index] : ''}
								</h4>
								<BsFillCaretRightFill className="activity-form-input-select-arrow" style={selectArrowStyle[index]} />
								<hr style={{ margin: '10px 0', color: '#016dc4', height: '3px' }} />
								<div style={{ overflowY: 'scroll', maxHeight: '300px' }}>
								{contest.dropdownOptions.map((option, optionIndex) => (
									<h4
									key={option.id}
									className="activity-form-input-select-option"
									onClick={() => changeSelectedValue(index, optionIndex)}
									>
									{option.dropdown}
									</h4>
								))}
								</div>
							</div>
							</div>
							</div>
							</div>
						) : null}
						</div>
						))}

					</div>
					<div className="activity-content-point-container">
						<h2 className="activity-content-your-point">{t("yourpoints")}</h2>
						<img
							src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star.png"
							alt="pointer star"
							className="activity-content-point-star"
						/>
						<h2 className="activity-content-point-value">{user.totalPoints}</h2>
					</div>
					<div className="activity-submit-footer">
						<Loader component={<input type="submit" value={t("submit")} className="activity-submit-btn" />} />
						<Link to="/activityone">
							<h6 className="activity-content-back-btn">{t("back")}</h6>
						</Link>
						<Modal show={showModal} onHide={() => setShowModal(false)} centered>
							<Modal.Body>
								<div className="activity-content-modal-image">
									<img
										src={smileFace}
										alt="baby face one"
										style={{ width: "100%" }}
									/>
								</div>
								<h1 className="activity-modal-title">{t("submitEntries")}</h1>
								<h5 className="activity-modal-subtitle-yellow">
									{t("sureToUse")} {num.length * contest.pointsPerEntry} {t("pointSubmit")} {num.length} {t("entriesQuestion")}
								</h5>
								<h6 className="activity-modal-yes-btn" onClick={handleSubmit}>
									{t("yescap")}
								</h6>
								<h6 className="activity-modal-no-btn" onClick={() => setShowModal(false)}>
									{t("nocap")}
								</h6>
							</Modal.Body>
						</Modal>
						<Modal show={showModalTwo} onHide={() => setShowModalTwo(false)} centered>
							<Modal.Body>
								<div className="activity-content-baby-face">
									<img
										src={loveFace}
										alt="baby face one"
										style={{ width: "100%" }}
									/>
								</div>
								<h1 className="activity-modal-title">{t("tqsubmission")}</h1>
								<h6 className="activity-modal-title">{t("stayTune")}</h6>
								<h6
									className="activity-modal-go-btn"
									onClick={() => {
										setShowModalTwo(false);
										history.push("/campaign");
									}}
								>
									{t("continue")}
								</h6>
							</Modal.Body>
						</Modal>
					</div>
				</form>
			</div>
			<Footer />
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		language: state.pageReducer.language
	};
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: "IS_LOADING" }),
		loaded: () => dispatch({ type: "IS_LOADED" })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
