import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
// import { histor } from 'react-router-dom'
import axios from "axios";
import Footer from "../../../component/dashboard/Footer";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Header from "../../../component/dashboard/Header";
import info from "../../../assets/Frame 2608150(1).png";
import uploadIcon from "../../../assets/file-upload 1.png";
import trash from "../../../assets/camera (1) 1(1).png";
import checkmark from "../../../assets/svgs/checkmark.svg";
import cancel from "../../../assets/svgs/cancel.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import physicalSample from "../../../assets/physicalSample.png";
// import ecommSample from "../../../assets/ecommSample.png";
import physicalSampleEn from "../../../assets/physicalSampleEn.png";
import physicalSampleBm from "../../../assets/physicalSampleBm.png";
import ecommSampleEn from "../../../assets/ecommSampleEn.png";
import ecommSampleBm from "../../../assets/ecommSampleBm.png";
import uploadReceiptBtnEn from "../../../assets/upload-receipt-btn-en.jpeg";
import uploadReceiptBtnBm from "../../../assets/upload-receipt-btn-bm.jpeg";

const UploadReceipt = ({ history }) => {
	const { t } = useTranslation();
	const retailers = useSelector(state => state.pageReducer.retailers).sort((a, b) => {
		if (a.label === "Others") return 1; // "Others" should be placed at the end
		if (b.label === "Others") return -1; // "Others" should be placed at the end
		return a.label.localeCompare(b.label); // Sort other items alphabetically
	});
	const skus = useSelector(state => state.pageReducer.skus);
	const language = useSelector(state => state.pageReducer.language);
	const token = useSelector(state => state.userReducer.token);
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState(null);
	const [imageFile, setImageFile] = useState(null);
	const [confirm, setConfirm] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [products, setProducts] = useState([{ skuId: "", quantity: 0 }]);
	const [storeName, setStoreName] = useState("");
	const [receiptDate, setReceiptDate] = useState("");
	const [invoiceNo, setInvoiceNo] = useState("");
	// const [amount, setAmount] = useState(0);
	const [storeLabel, setStoreLabel] = useState("");
	const [showSample, setShowSample] = useState(false);
	const [otherStore, setOtherStore] = useState("");
	const [showTnc, setShowTnc] = useState(false);

	useEffect(() => {
		(async () => {
			if (!retailers || !retailers.length) {
				const { data } = await axios.post("/drypers-baby-club/api/receipt/retailers");
				if (data && data.data)
					dispatch({
						type: "SET_RETAILERS",
						payload: data.data.sort((a, b) => {
							if (a.label === "Others") return 1; // "Others" should be placed at the end
							if (b.label === "Others") return -1; // "Others" should be placed at the end
							return a.label.localeCompare(b.label); // Sort other items alphabetically
						})
					});
			}
		})();
	}, [retailers, retailers.length]);

	useEffect(() => {
		(async () => {
			if (!skus || !retailers.length) {
				const { data } = await axios.post("/drypers-baby-club/api/receipt/skus");
				if (data && data.data)
					dispatch({
						type: "SET_SKUS",
						payload: data.data
					});
			}
		})();
	}, [skus, skus.length]);

	const handleDisplayImage = e => {
		let render = new FileReader();

		if (e.target.files[0]) {
			setImageFile(e.target.files[0]);
			render.readAsDataURL(e.target.files[0]);
			render.onload = res => {
				setImageUrl(res.target.result);
			};
		}
	};

	const handleSubmit = async e => {
		e.preventDefault();
		if (!loading) {
			const checkOthers = /others/i.test(storeLabel);
			// check if got everything
			if (!imageFile || !storeName || !receiptDate || !invoiceNo)
				setError("Please fill in all the fields to submit your receipt.");
			else if (checkOthers && !otherStore) setError("Please specify the store of purchased.");
			else {
				const sendProducts = products.filter(product => product.skuId && product.quantity);
				if (!sendProducts || !sendProducts.length)
					setError("Please select your product purchased and add quantity to proceed");
				else {
					const differenceInDays = (date1, date2) => {
						const startDate = new Date(date1);
						const endDate = new Date(date2);
						const ms = 1000;
						const s = 60;
						const m = 60;
						const h = 24;

						const timeDiff = endDate - startDate;
						const dayDiff = timeDiff / (ms * s * m * h);
						return Math.ceil(dayDiff);
					};
					const today = new Date();
					const rDate = new Date(receiptDate);
					const startDate = new Date(new Date(2024, 4, 1).setHours(0, 0, 0, 0));
					const checkDateDiff = differenceInDays(rDate, today);

					if (checkDateDiff < 0 || checkDateDiff > 30)
						setError("You can only submit receipt that are not older than 30 days before.");
					else if (rDate < startDate) setError(t("receiptDateError"));
					else {
						const formData = new FormData();
						formData.append("image", imageFile);
						formData.append("retailerId", storeName);
						formData.append("receiptDate", receiptDate);
						formData.append("invoiceNo", invoiceNo.trim());
						// formData.append("amount", amount);
						formData.append("products", JSON.stringify(sendProducts));
						formData.append("otherStore", otherStore);
						// console.log({
						// 	imageFile,
						// 	storeName,
						// 	receiptDate,
						// 	invoiceNo,
						// 	product: JSON.stringify(sendProducts),
						// 	otherStore
						// });
						try {
							setLoading(true);
							await axios.post("/drypers-baby-club/api/receipt/upload", formData, {
								headers: {
									"Content-Type": "multipart/form-data",
									Authorization: `Bearer ${token}`
								}
							});
							setSuccess(true);
						} catch (error) {
							setLoading(false);
							if (error?.response?.data?.error) setError(t(error.response.data.error));
							else setError(t(error.message));
						}
					}
				}
			}
		}
	};

	const addList = () => {
		const previousObj = products[products.length - 1];
		if (!previousObj || !previousObj.skuId || !previousObj.quantity)
			setError("Please select an option / add quantity to add more products");
		else {
			if (products.length !== skus.length) setProducts([...products, { skuId: "", quantity: 0 }]);
		}
	};

	const selectProduct = (value, idx) => {
		const exist = products.filter(product => product.skuId === value)[0];
		if (exist) setError("You have already selected this product");
		else {
			const newProducts = products.map((product, i) => {
				if (i !== idx) return product;
				else
					return {
						...product,
						skuId: value
					};
			});
			setProducts(newProducts);
		}
	};

	const changeQuantity = (value, idx) => {
		const newProducts = products.map((product, i) => {
			if (i !== idx) return product;
			else
				return {
					...product,
					quantity: parseInt(value)
				};
		});

		setProducts(newProducts);
	};

	return (
		<div id="upload-receipt-page">
			<Header />
			<div className="content">
				<ContentHeader title={t("uploadReceipt")} />
				<div className="px-3 upload-receipt-content">
					<img
						src={language === "en" ? uploadReceiptBtnEn : uploadReceiptBtnBm}
						alt="for-all-drypers-baby-care-products-only"
						className="mt-3"
						style={{ width: "100%" }}
					/>
					<form onSubmit={handleSubmit} className="vinda-form" style={{ padding: "0" }}>
						<div className="upload-receipt-input-file-box">
							{imageUrl ? (
								<>
									<img
										src={imageUrl}
										alt="receipt"
										style={{
											maxWidth: "100%",
											maxHeight: "100%",
											height: "100%",
											width: "100%",
											objectFit: "cover"
										}}
									/>
									<img
										src={trash}
										alt=""
										onClick={() => setConfirm(true)}
										style={{
											width: "42px",
											position: "absolute",
											zIndex: "99",
											right: "5%",
											top: "5%"
										}}
									/>
								</>
							) : (
								<div className="upload-receipt-input-file-grid">
									<div className="upload-receipt-input-file-textone">
										<img
											style={{ width: "48px" }}
											// onClick={() => uploadref.current.click()}
											className="m-auto fit-contain cursor-pointer"
											src={uploadIcon}
											alt=""
										/>
										<h3 className="t-white mt-2">{t("uploadReceiptPhoto")}</h3>
										<p className="t-white" style={{ fontSize: "12px" }}>
											{t("supportedFormat")}
											<br />
											{t("clearReceipt")}
										</p>
									</div>
									<div
										style={{
											left: "3%",
											top: "5%",
											position: "absolute",
											display: "block",
											zIndex: 10
										}}
										onClick={() => setShowSample(true)}
										className="align-right info cursor-pointer d-flex align-items-center"
									>
										<img style={{ width: "26px", height: "26px" }} src={info} alt="" />
										<p className="ms-2 uc-tnc m-0 p-0">{t("receiptSample")}</p>
									</div>

									<input
										type="file"
										name="receiptImage"
										accept="image/jpeg,image/jpg,image/png,application/pdf"
										// ref={uploadref}
										onChange={e => handleDisplayImage(e)}
										style={{
											position: "absolute",
											top: 0,
											left: 0,
											width: "100%",
											height: "100%",
											opacity: 0
										}}
										required
									/>
								</div>
							)}
						</div>
						<p style={{ fontSize: "14px" }}>{t("receiptValidateNote")}</p>
						<div className="vinda-form-group">
							<label htmlFor="storeName" className="vinda-form-label" style={{ color: "black" }}>
								{t("storeName")}
							</label>
							<select
								id="storeName"
								name="storeName"
								value={storeName}
								onChange={e => {
									setStoreName(e.target.value);
									const label = retailers.find(retailer => retailer.value === e.target.value).label;
									setStoreLabel(label);
									setOtherStore("");
								}}
								className="receipt-form-input"
								required
							>
								<option disabled value="">
									{t("select")}
								</option>
								{retailers
									.sort((a, b) => {
										if (a.label === "Others") return 1; // "Others" should be placed at the end
										if (b.label === "Others") return -1; // "Others" should be placed at the end
										return a.label.localeCompare(b.label); // Sort other items alphabetically
									})
									.map(retailer => (
										<option value={retailer.value} key={retailer.value}>
											{retailer.label}
										</option>
									))}
							</select>
							{/others/i.test(storeLabel) ? (
								<div>
									<label style={{ fontSize: "12px" }}>{t("pleaseSpecify")}</label>
									<br />
									<input
										type="text"
										style={{
											border: "none",
											boxShadow: "none",
											borderBottom: "1px #c4c4c4 solid",
											width: "100%",
											marginBottom: "20px"
										}}
										id="otherStore"
										name="otherStore"
										value={otherStore}
										onChange={e => setOtherStore(e.target.value)}
										required
									/>
								</div>
							) : null}
							<label htmlFor="receiptDate" className="mt-3 vinda-form-label" style={{ color: "black" }}>
								{t("receiptDate")}
							</label>
							<input
								type="date"
								className="mt-2 receipt-form-input"
								id="receiptDate"
								name="receiptDate"
								value={receiptDate}
								onChange={e => setReceiptDate(e.target.value)}
								required
							/>
							<label htmlFor="receiptNo" className="mt-3 vinda-form-label" style={{ color: "black" }}>
								{t("receiptNumber")}
							</label>
							<input
								type="text"
								className="mt-2 receipt-form-input"
								id="receiptNo"
								name="receiptNo"
								placeholder={t("receiptNumberPlaceholder")}
								value={invoiceNo}
								onChange={e => setInvoiceNo(e.target.value)}
								required
							/>
							{/* <label htmlFor="amount" className="mt-3 vinda-form-label">
								Receipt Amount
							</label>
							<input
								type="number"
								className="mt-2 receipt-form-input"
								id="amount"
								name="amount"
								placeholder="Enter the amount"
								value={amount}
								onChange={e => setAmount(parseInt(e.target.value))}
								min={0}
								step={0.01}
								required
							/> */}
							<div className="d-flex justify-content-between">
								<label htmlFor="amount" className="mt-3 vinda-form-label" style={{ color: "black" }}>
									{t("purchasedProducts")}
								</label>
								<label htmlFor="amount" className="mt-3 vinda-form-label" style={{ color: "black" }}>
									{t("quantity")}
								</label>
							</div>

							{products.map((product, idx) => {
								return (
									<div className="d-flex" key={idx}>
										<select
											id={`product-${idx + 1}`}
											name="product"
											style={{ width: "90%" }}
											className="mt-2 receipt-form-input"
											value={product.skuId}
											onChange={e => selectProduct(e.target.value, idx)}
										>
											<option disabled value="">
												{t("select")}
											</option>
											{skus.map(sku => (
												<option value={sku.value} key={sku.value}>
													{sku.label}
												</option>
											))}
										</select>
										<div>
											<input
												style={{ width: "90%", marginLeft: "auto" }}
												type="number"
												className="mt-2 receipt-form-input"
												id="amount"
												name="amount"
												placeholder={t("quantityPlaceholder")}
												value={product.quantity}
												onChange={e => changeQuantity(e.target.value, idx)}
												min={0}
												step={1}
												required
											/>
										</div>
									</div>
								);
							})}
							<p
								className="mt-4"
								style={{ textAlign: "center", color: "#ffc439", fontWeight: "700", cursor: "pointer" }}
								onClick={addList}
							>
								+ {t("addProducts")}
							</p>
							<p style={{ fontSize: "13px", textAlign: "center" }} className="mt-3">
								{t("doublePointsNote")}
							</p>
							<p className="mt-3" style={{ fontSize: "13px", textAlign: "center" }}>
								{/* {t("click") + " " + <span>t("herecap")</span> + " " + t("termCon")} */}
								{/* {`${t("click")} ${t("herecap")} ${t("termCon")}`} */}
								{t("click")}{" "}
								<span
									style={{ color: "#2fa8cc", fontWeight: "bold", cursor: "pointer" }}
									onClick={() => setShowTnc(true)}
								>
									{t("herecap")}
								</span>{" "}
								{t("termCon")}
							</p>

							{loading ? (
								<div className="text-center mt-4">
									<Spinner animation="grow" variant="warning" />
								</div>
							) : (
								<input
									type="submit"
									style={{ display: "block" }}
									value={t("submit")}
									id="ucSubmit"
									className="m-auto pickAddressConfirmButton"
								/>
							)}
						</div>
					</form>
				</div>
			</div>
			<Footer />
			<Modal show={success} centered>
				<Modal.Body className="text-center">
					<div>
						<img src={checkmark} alt="check" className="m-0 p-0" style={{ width: "80px" }} />
						<p
							className="upload-receipt-modal-title"
							style={{ fontSize: "24px", fontWeight: "bold", marginTop: "6px" }}
						>
							{t("succ")}!
						</p>
						<p className="pickSampleModalText">{t("uploadSuccess")}</p>
					</div>
					<div>
						<button
							onClick={() => history.push("/dashboard")}
							className="pickAddressConfirmButton"
							style={{ background: "#02bc7d" }}
						>
							{t("done")}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={confirm} centered>
				<Modal.Body className="text-center">
					<div>
						<p style={{ fontSize: "22px", fontWeight: "bold" }}>{t("areyousuretitle")}?</p>
						<p className="mt-3 pickSampleModalText">{t("areyousuretodelete")}?</p>
					</div>
					<div>
						<button
							onClick={() => {
								setImageUrl(null);
								setConfirm(false);
							}}
							className="pickAddressConfirmButton"
						>
							{t("delete")}
						</button>
						<button
							onClick={() => setConfirm(false)}
							className="pickAddressConfirmButton mt-3"
							style={{ background: "transparent", border: "2px solid #fea30b", color: "#fea30b" }}
						>
							{t("cancel")}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={!!error} centered>
				<Modal.Body className="text-center">
					<div>
						<img src={cancel} alt="error" className="m-0 p-0" style={{ width: "80px" }} />
						<p
							className="upload-receipt-modal-title"
							style={{ fontSize: "24px", fontWeight: "bold", marginTop: "6px" }}
						>
							{t("failed")}
						</p>
						<p className="pickSampleModalText">{error}</p>
					</div>
					<div>
						<button onClick={() => setError(null)} className="pickAddressConfirmButton">
							{t("submitAgain")}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal show={showSample} centered onHide={() => setShowSample(false)}>
				<Modal.Body className="text-center">
					<div className="pb-5">
						<p style={{ fontSize: "22px", fontWeight: "bold" }}>{t("receiptSample")}</p>
						<Slider autoplay={false} dots={true} arrows={false}>
							<div>
								{/* <p>{t("offlineSample").slice(0,10)} {t("offlineSample").slice(10, 17)} {t("offlineSample").slice(17)}</p> */}
								<p>
									{language === "en" ? (
										<>
											Sample of <b>offline</b> purchase receipt:
										</>
									) : (
										<>
											Contoh resit pembelian <b>offline:</b>
										</>
									)}
								</p>
								<img src={language === "en" ? physicalSampleEn : physicalSampleBm} alt="physical receipt sample" />
							</div>

							<div>
								{/* <p className="fw-bold">{t("onlineSample")}</p> */}
								<p>
									{language === "en" ? (
										<>
											Sample of <b>online</b> purchase receipt:
										</>
									) : (
										<>
											Contoh resit pembelian <b>online:</b>
										</>
									)}
								</p>
								<img src={language === "en" ? ecommSampleEn : ecommSampleBm} alt="ecomm receipt sample" />
							</div>
						</Slider>
					</div>
					<button onClick={() => setShowSample(false)} className="pickAddressConfirmButton">
						{t("closeButton")}
					</button>
				</Modal.Body>
			</Modal>
			<Modal show={showTnc} centered onHide={() => setShowTnc(false)}>
				<Modal.Body>
					<div>
						<p>{t("termsandcon")}</p>

						<ol style={{ fontSize: "14px" }}>
							<li>
								<strong>{t("eligibility")}</strong>: {t("eligibilityTnc")}
							</li>
							<li>
								<strong>{t("uploadRequirement")}</strong>: {t("uploadRequirementTnc")}
							</li>
							<li>
								<strong>{t("eligibleProduct")}</strong>: {t("eligibleProductTnc")}
							</li>
							<li>
								<strong>{t("pointAllocation")}</strong>: {t("pointAllocationTnc")}
							</li>
							<li>
								<strong>{t("dpd")}</strong>: {t("dpdTnc")}
							</li>
							<li>
								<strong>{t("validPeriod")}</strong>: {t("validPeriodTnc")}
							</li>
							<li>
								<strong>{t("fraudPrevent")}</strong>: {t("fraudPreventTnc")}
							</li>
							<li>{t("duplicateTnc")}</li>
							<li>
								<strong>{t("supportedFormatTitle")}</strong>: {t("supportedFormatTnc")}
							</li>
							<li>{t("vindaTnc")}</li>
							<li>
								{t("clickHereFor")} {t("imageAndPoints")}
							</li>
						</ol>

						<div>
							<button onClick={() => setShowTnc(false)} className="pickAddressConfirmButton">
								{t("done")}
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default UploadReceipt;
