import { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { MdAddCircleOutline } from "react-icons/md";
import axios from "axios";
import { connect } from "react-redux";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import Loader from "../../../component/Loader";
import { voucherDashboardColumns } from "../../../config/mdbColumns";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";

const VoucherDashboard = ({ loading, loaded, history }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		loading();
		axios
			.get("/api/admin/fetch/dashboard/voucher")
			.then(res => {
				loaded();
				setData(res.data);
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleActive = e => {
		loading();
		axios
			.post("/api/admin/setting/hide/voucher", { id: e.target.id })
			.then(res => {
				setData(data.map(voucher => (voucher.id === e.target.id ? { ...voucher, active: !voucher.active } : voucher)));
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
	};

	const tableData = {
		columns: voucherDashboardColumns,
		rows: data.map(x => {
			return {
				...x,
				value: `RM${x.value.toFixed(2)}`,
				voucherImage: (
					<img src={x.voucherImage} alt="voucher" className="img-fluid" style={{ height: "80px", width: "auto" }} />
				),
				details: (
					<button
						type="button"
						className="btn btn-primary"
						onClick={() => history.push({ pathname: "/admin/voucher/batch/details", state: { id: x.id, data: x } })}
					>
						Details
					</button>
				),
				showItem: <input type="checkbox" id={x.id} name={x.id} onChange={handleActive} checked={x.active} />
			};
		})
	};
	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					{/* contents */}
					<h3>Voucher Dashboard</h3>

					<div className="mt-5">
						<button type="button" className="btn btn-primary" onClick={() => history.push("/admin/voucher/type/add")}>
							<MdAddCircleOutline className="me-2" />
							Add Voucher
						</button>

						<div className="mt-5 card">
							<div className="card-header">
								<h5 className="card-title">Vouchers</h5>
							</div>

							<div className="card-body text-center">
								<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(VoucherDashboard);
