import { useState, useEffect, useMemo } from "react";
import { MDBDataTableV5 } from "mdbreact";
import { connect } from "react-redux";
import { MdAddCircleOutline } from "react-icons/md";
import axios from "axios";
import { IS_LOADING, IS_LOADED } from "../../../redux/types";
import AdminSidebar from "../../../component/admin/Sidebar";
import AdminTopbar from "../../../component/admin/Topbar";
import Loader from "../../../component/Loader";
import { journeyDashboardColumns } from "../../../config/mdbColumns";

const JourneyDashboard = ({ loading, loaded, history, token }) => {
	const [data, setData] = useState([]);

	useEffect(() => {
		loading();
		axios
			.get("/api/journey/fetch", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			})
			.then(res => {
				setData(res.data);
				loaded();
			})
			.catch(err => {
				loaded();
				alert(err.response.data.error);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const truncateText = text => (text && text.length > 40 ? text.substr(0, 40) + "..." : text);

	const handleActive = id => {
		axios
			.post(
				"/api/admin/setting/journey/show",
				{ id },
				{
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
			)
			.then(() => {
				setData(
					data.map(x => {
						if (x.id !== id) {
							return x;
						} else {
							return {
								...x,
								active: !x.active
							};
						}
					})
				);
			})
			.catch(err => alert(err.response.data.error));
	};

	const tableData = useMemo(() => {
		return {
			columns: journeyDashboardColumns,
			rows: data.map(journey => {
				return {
					...journey,

					home: journey.home,
					children: journey.children,
					points: journey.points,
					product: journey.product,
					description: truncateText(journey.description.en),
					descriptionBM: truncateText(journey.description.bm),
					progressPoints: journey.progressPoints,
					edit: (
						<button
							type="button"
							className="btn btn-info"
							onClick={() => history.push({ pathname: "/admin/journey/edit", data: journey })}
						>
							Edit
						</button>
					)
				};
			})
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>journey Dashboard</h3>

					{/* <button type="button" className="mt-3 btn btn-primary" onClick={() => history.push("/admin/journey/add")}>
						<MdAddCircleOutline className="me-2" />
						Add journey
					</button> */}

					<div className="mt-5 card">
						<div className="card-header">
							<h5 className="card-title">journey</h5>
						</div>

						<div className="card-body text-center">
							<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		token: state.userReducer.token
	};
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(JourneyDashboard);
