import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MDBDataTableV5 } from "mdbreact";
import axios from "axios";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import { IS_LOADING, IS_LOADED } from "../../../../redux/types";
import Loader from "../../../../component/Loader";
import { voucherDetailsColumns } from "../../../../config/mdbColumns";

const VoucherDetails = ({ history, location, loading, loaded }) => {
	const [data, setdata] = useState([]);

	useEffect(() => {
		if (location.state) {
			const { id } = location.state.batch;
			loading();
			axios
				.get(`/api/admin/fetch/dashboard/voucher/details?id=${id}`)
				.then(res => {
					setdata(res.data);
					loaded();
				})
				.catch(err => {
					loaded();
					alert(err.response.data.error);
				});
		} else {
			history.push("/admin/voucher/dashboard");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableData = {
		columns: voucherDetailsColumns,
		rows: data
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Batch Details</h3>

					<ul className="mt-5 list-group">
						<li className="list-group-item">
							Voucher ID: <strong>{location.state && location.state.type ? location.state.type.itemId : ""}</strong>
						</li>

						<li className="list-group-item">
							Batch ID: <strong>{location.state && location.state.batch ? location.state.batch.id : ""}</strong>
						</li>

						<li className="list-group-item">
							Voucher Name: <strong>{location.state && location.state.type ? location.state.type.name : ""}</strong>
						</li>

						<li className="list-group-item">
							Voucher Value: <strong>RM{location.state && location.state.type ? location.state.type.value.toFixed(2) : ""}</strong>
						</li>

						<li className="list-group-item">
							Points: <strong>{location.state && location.state.type ? location.state.type.points : ""}</strong>
						</li>

						<li className="list-group-item">
							Available: <strong>{location.state && location.state.batch ? location.state.batch.available : ""}</strong>
						</li>

						<li className="list-group-item">
							Redeemed: <strong>{location.state && location.state.batch ? location.state.batch.redeemed : ""}</strong>
						</li>

						<li className="list-group-item">
							Used: <strong>{location.state && location.state.batch ? location.state.batch.used : ""}</strong>
						</li>
					</ul>

					<div className="mt-5">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">Vouchers</h5>
							</div>

							<div className="card-body text-center">
								<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(VoucherDetails);
