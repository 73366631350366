import React, { useState } from "react";
// Components
import Header from "../../../component/dashboard/Header";
import Footer from "../../../component/dashboard/Footer";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Catalog from "../../../component/pointshop/Catalog";
import OrderHistory from "../../../component/pointshop/OrderHistory";
import VoucherHistory from "../../../component/pointshop/VoucherHistory";
import { useTranslation } from "react-i18next";

const PointShop = () => {
	const { t } = useTranslation();
	const [isPage, setPage] = useState("shop");

	return (
		<div id="point-shop">
			<Header />
			<div className="content">
				<ContentHeader title={t("myrewards")} />

				<div className="pointShopTopButtonContainer">
					<div className={`pointShopTopButton ${isPage === "shop" ? "active" : ""}`} onClick={() => setPage("shop")}>
						<p className="m-0 p-0">{t("pointshopChoice")}</p>
					</div>
					<div className={`pointShopTopButton ${isPage === "history" ? "active" : ""}`} onClick={() => setPage("history")}>
						<p className="m-0 p-0">{t("orderhistory")}</p>
					</div>
					<div className={`pointShopTopButton ${isPage === "voucher" ? "active" : ""}`} onClick={() => setPage("voucher")}>
						<p className="m-0 p-0">{t("myvoucher")}</p>
					</div>
				</div>

				<div style={{ marginBottom: "150px", marginTop: "25px" }}>
					{isPage === "shop" ? <Catalog /> : isPage === "history" ? <OrderHistory /> : isPage === "voucher" ? <VoucherHistory /> : null}
				</div>

				<Footer />
			</div>
		</div>
	);
};

export default PointShop;
