import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { TiSocialFacebook } from "react-icons/ti";
import { RiInstagramLine } from "react-icons/ri";
import { BsYoutube } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Footer = ({ currentLanguage }) => {
	const { t } = useTranslation();
	const goToLinks = val => {
		if (val === "story") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/our-story/`);
		else if (val === "product") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/products/`);
		else if (val === "sustain") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/sustainability/`);
		else if (val === "dbc") return (window.location.href = `https://www.drypers.com.my/drypers-baby-club/${currentLanguage}`);
		else if (val === "latest") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/latest-happenings/`);
		else if (val === "tips") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/parenting-tips/`);
		else if (val === "shop") return (window.location.href = `https://www.drypers.com.my/${currentLanguage}`);
		else if (val === "privacy") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/privacy-policy/#privacy-policy`);
		else if (val === "contactus") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/contact-us/`);
		else if (val === "terms") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/terms-conditions/`);
		else if (val === "cookies") return (window.location.href = `https://www.drypers.com.my/drypers_my${currentLanguage === "bm" ? currentLanguage : ""}/privacy-policy/#use-of-cookies`);
		else if (val === "fb") return (window.location.href = `https://www.facebook.com/DrypersMY/`);
		else if (val === "ig") return (window.location.href = `https://www.instagram.com/drypersmy/?hl=en`);
		else if (val === "yt") return (window.location.href = `https://www.youtube.com/user/drypersmalaysia`);
		else console.log("no link");
	};

	return (
		<div id="footer">
			<div className="mobile-footer">
				<NavLink
					to={{ pathname: "/scancode", isDesktop: false }}
					activeClassName="active"
					className="footer-item"
					style={{ textDecoration: "none" }}
				>
					<div className="footer-icon-container">
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/scan.png" className="img-fluid footer-icon" alt="footer" />
					</div>

					<p className="footer-label m-0 p-0">{t("scancodecap")}</p>
				</NavLink>
				<NavLink to="/pointshop" activeClassName="active" className="footer-item" style={{ textDecoration: "none" }}>
					<div className="footer-icon-container">
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/handPoint.png" className="img-fluid footer-icon" alt="footer" />
					</div>

					<p className="footer-label m-0 p-0">{t("myrewards")}</p>
				</NavLink>
				<NavLink to="/inbox" activeClassName="active" className="footer-item" style={{ textDecoration: "none" }}>
					<div className="footer-icon-container">
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/inbox.png" className="img-fluid footer-icon" alt="footer" />
					</div>

					<p className="footer-label m-0 p-0">{t("inbox")}</p>
				</NavLink>
				<NavLink to="/userprofile" activeClassName="active" className="footer-item" style={{ textDecoration: "none" }}>
					<div className="footer-icon-container">
						<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/profile.png" className="img-fluid footer-icon" alt="footer" />
					</div>

					<p className="footer-label m-0 p-0">{t("profile")}</p>
				</NavLink>
			</div>

			<div className="desktop-footer">
				<div className="footer-logo-container">
					<img src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/images/club-logo-white-2.png" alt="dryper logo" className="img-fluid" />
				</div>

				<div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 px-5">
					<div className="footer-menu-container mb-3">
						<div className="footer-menu-item" onClick={() => goToLinks("story")}>
							<p style={{ cursor: "pointer" }} className="m-0 p-0">
								{t("ourstory")}
							</p>
						</div>
						<div className="footer-menu-item" onClick={() => goToLinks("privacy")}>
							<p style={{ cursor: "pointer" }} className="m-0 p-0">
								{t("privacy")}
							</p>
						</div>
						<div className="footer-menu-item" onClick={() => goToLinks("contactus")}>
							<p style={{ cursor: "pointer" }} className="m-0 p-0">
								{t("contactus")}
							</p>
						</div>
						<div className="footer-menu-item" onClick={() => goToLinks("terms")}>
							<p style={{ cursor: "pointer" }} className="m-0 p-0">
								{t("terms")} &amp; {t("condition")}
							</p>
						</div>
						<div className="footer-menu-item" onClick={() => goToLinks("cookies")}>
							<p style={{ cursor: "pointer" }} className="m-0 p-0">
								{t("useofcook")}
							</p>
						</div>
					</div>
					<div className="align-self-end">
						<p style={{ userSelect: "none" }} className="p-0 m-0">
							&copy;{t("allrights")}
						</p>
					</div>
				</div>

				<div className="d-flex justify-content-center align-items-center">
					<div>
						<p style={{ userSelect: "none" }} className="m-0 p-0">
							{t("stayconnected")}
						</p>
						<div className="d-flex justify-content-around">
							<div style={{ cursor: "pointer" }} className="social-button" onClick={() => goToLinks("fb")}>
								<TiSocialFacebook />
							</div>
							<div style={{ cursor: "pointer" }} className="social-button" onClick={() => goToLinks("ig")}>
								<RiInstagramLine />
							</div>
							<div style={{ cursor: "pointer" }} className="social-button" onClick={() => goToLinks("yt")}>
								<BsYoutube />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		currentLanguage: state.pageReducer.language
	};
};

export default connect(mapStateToProps)(Footer);
