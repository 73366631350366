import { useState } from "react";
import { connect } from "react-redux";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import Loader from "../../../../component/Loader";
import massCredit from "../../../../assets/files/massCredit.csv";
import massRefund from "../../../../assets/files/massRefund.csv";
import { IS_LOADING, IS_LOADED } from "../../../../redux/types";
import axios from "axios";
import { Modal } from "react-bootstrap";
// import { addNews } from "../../../../redux/actions/adminActions";

const MassCredit = ({ history, loading, loaded }) => {
	const [data, setData] = useState({
		type: "",
		title: "",
		points: 0
	});
	const [csv, setCsv] = useState(null);
	const [uploaded, setUploaded] = useState(false);
	const [csvData, setCsvData] = useState(null);

	const handleChange = e =>
		setData({
			...data,
			[e.target.name]: e.target.value
		});

	const handleFile = e => {
		if (data.type) {
			if (!uploaded) {
				const file = e.target.files[0];
				// console.log(file);
				const formData = new FormData();
				formData.append("file", file);
				formData.append("type", data.type);

				if (data.type === "refund") {
					loading();
					axios
						.post("/api/admin/setting/massCredit/check", formData)
						.then(res => {
							setCsv(file);
							setUploaded(true);
							setCsvData(res.data);
							loaded();
						})
						.catch(err => {
							alert(err.response.data.error);
							setCsv(null);
							setUploaded(false);
							setCsvData(null);
							e.target.value = null;
							loaded();
						});
				} else {
					// campaign
					if (!data.title || !data.points) alert("Please enter campaign title and credit points");
					else {
						loading();
						axios
							.post("/api/admin/setting/massCredit/check", formData)
							.then(res => {
								setCsv(file);
								setUploaded(true);
								setCsvData(res.data);
								loaded();
							})
							.catch(err => {
								alert(err.response.data.error);
								setCsv(null);
								setUploaded(false);
								setCsvData(null);
								e.target.value = null;
								loaded();
							});
					}
				}
			}
		} else {
			alert("Please select credit type first");
			e.target.value = null;
		}
	};

	const handleCancel = () => {
		setCsv(null);
		setUploaded(false);
		setCsvData(null);
	};

	const handleConfirm = () => {
		if (csv) {
			const formData = new FormData();
			if (data.type && data.type === "campaign" && data.title && data.points) {
				formData.append("file", csv);
				formData.append("type", data.type);
				formData.append("title", data.title);
				formData.append("points", data.points);
				loading();
				axios
					.post("/api/admin/setting/massCredit", formData)
					.then(res => {
						loaded();
						history.push("/admin/massCredit/dashboard");
					})
					.catch(err => {
						alert(err.response.data.error);
						loaded();
					});
			} else if (data.type && data.type === "refund") {
				formData.append("file", csv);
				formData.append("type", data.type);
				loading();
				axios
					.post("/api/admin/setting/massCredit", formData)
					.then(res => {
						loaded();
						history.push("/admin/massCredit/dashboard");
					})
					.catch(err => {
						alert(err.response.data.error);
						loaded();
					});
			} else {
				alert("Please reload and try again");
			}
		} else {
			alert("Please reload and try again");
		}
	};

	// const handleSubmit = e => {
	// 	e.preventDefault();

	// 	console.log(csv);
	// 	console.log(data);
	// };

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Mass Credit</h3>
					<div className="mt-5">
						<div>
							<div className="form-group">
								<label htmlFor="type">Type:</label>
								<select id="type" name="type" className="form-select" value={data.type} onChange={handleChange} required>
									<option disabled value="">
										Select Type
									</option>
									<option value="campaign">Campaign</option>
									<option value="refund">Refund</option>
								</select>
							</div>

							{data.type === "campaign" ? (
								<div className="form-group mt-3">
									<label htmlFor="title">Campaign Title:</label>
									<input
										type="text"
										id="title"
										name="title"
										className="form-control"
										placeholder="(eg: Little Voices Panel April)"
										value={data.title}
										onChange={handleChange}
										required
									/>
								</div>
							) : null}

							{data.type === "campaign" ? (
								<div className="form-group mt-3">
									<label htmlFor="points">Campaign Points:</label>
									<input type="number" id="points" name="points" className="form-control" value={data.points} onChange={handleChange} required />
								</div>
							) : null}

							{data.type ? (
								<div className="mt-3">
									<p className="m-0 p-0">Download {data.type} template here</p>
									{data.type === "campaign" ? (
										<a href={massCredit} className="btn btn-primary" download="massCreditTemplate.csv">
											Download
										</a>
									) : data.type === "refund" ? (
										<a href={massRefund} className="btn btn-primary" download="massRefundTemplate.csv">
											Download
										</a>
									) : null}
								</div>
							) : null}

							{data.type && data.type === "refund" ? (
								<div className="form-group mt-3">
									<label htmlFor="file">
										Upload File Here:{" "}
										<small>
											<strong>Only CSV file is support now</strong>
										</small>
									</label>
									<input type="file" id="file" name="file" className="form-control" accept=".csv" onChange={handleFile} required />
								</div>
							) : data.type && data.type === "campaign" && data.points && data.title ? (
								<div className="form-group mt-3">
									<label htmlFor="file">
										Upload File Here:{" "}
										<small>
											<strong>Only CSV file is support now</strong>
										</small>
									</label>
									<input type="file" id="file" name="file" className="form-control" accept=".csv" onChange={handleFile} required />
								</div>
							) : null}

							{/* {data.type === "refund" && csv ? (
								<div className="form-group mt-5">
									<input type="submit" className="form-control btn btn-primary" value="SUBMIT" />
								</div>
							) : data.type === "campaign" && csv && data.title && data.points ? (
								<div className="form-group mt-5">
									<input type="submit" className="form-control btn btn-primary" value="SUBMIT" />
								</div>
							) : null} */}
						</div>
					</div>
				</div>
			</div>

			<Modal show={uploaded} backdrop="static" keyboard={false} centered>
				<Modal.Header>Confirm uploaded data</Modal.Header>

				<Modal.Body>
					<p>
						<small>
							<strong>*if the email is red in color means it does not exist</strong>
						</small>
					</p>
					<table className="table table-bordered table-striped table-hover">
						<thead>
							<tr>
								<th scope="col">Email</th>
								<th scope="col">Description</th>
								<th scope="col">Points</th>
							</tr>
						</thead>

						<tbody>
							{data.type === "campaign"
								? csvData &&
								  csvData.map((x, i) => (
										<tr key={i}>
											<th scope="row" style={!x.id ? { color: "red", fontWeight: "bold" } : {}}>
												{x.email}
											</th>
											<td>{data.title}</td>
											<td>{data.points}</td>
										</tr>
								  ))
								: data.type === "refund"
								? csvData &&
								  csvData.map((x, i) => (
										<tr key={i}>
											<th scope="row" style={!x.id ? { color: "red", fontWeight: "bold" } : {}}>
												{x.email}
											</th>
											<td>{x.description}</td>
											<td>{x.points}</td>
										</tr>
								  ))
								: null}
						</tbody>
					</table>
				</Modal.Body>

				<Loader
					component={
						<Modal.Footer>
							<button type="button" className="btn btn-secondary" onClick={handleCancel}>
								Cancel
							</button>
							<button type="button" className="btn btn-primary" onClick={handleConfirm}>
								Upload
							</button>
						</Modal.Footer>
					}
				/>
			</Modal>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(MassCredit);
