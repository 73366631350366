import { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { MDBDataTableV5 } from "mdbreact";
import AdminSidebar from "../../../../component/admin/Sidebar";
import AdminTopbar from "../../../../component/admin/Topbar";
import { FaFileUpload, FaFileDownload } from "react-icons/fa";
import voucherTemplate from "../../../../assets/files/voucherTemplate.csv";
import { voucherBatchColumns } from "../../../../config/mdbColumns";
import { IS_LOADING, IS_LOADED } from "../../../../redux/types";
import Loader from "../../../../component/Loader";

const BatchDetails = ({ location, history, loading, loaded }) => {
	const [data, setData] = useState([]);
	useEffect(() => {
		if (location.state && location.state.id) {
			const { id } = location.state;
			loading();
			axios
				.get(`/api/admin/fetch/dashboard/voucher/batch/details?id=${id}`)
				.then(res => {
					setData(res.data);
					loaded();
				})
				.catch(err => {
					loaded();
					alert(err);
				});
		} else {
			history.push("/admin/voucher/dashboard");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const tableData = {
		columns: voucherBatchColumns,
		rows: data.map(x => {
			return {
				...x,
				createdAt: new Date(x.createdAt).toLocaleString("en-GB"),
				details: (
					<button
						type="button"
						className="btn btn-primary"
						onClick={() =>
							history.push({
								pathname: "/admin/voucher/details",
								state: { batch: x, type: location.state && location.state.data ? location.state.data : null }
							})
						}
					>
						Details
					</button>
				)
			};
		})
	};

	return (
		<div className="admin-page">
			<AdminSidebar />

			<div className="admin-body">
				<AdminTopbar />

				<div className="admin-content">
					<h3>Voucher Batch Details</h3>

					<div className="mt-3 d-flex">
						<a href={voucherTemplate} download className="btn btn-info me-3">
							<FaFileDownload className="me-2" /> Download Template
						</a>
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => history.push({ pathname: "/admin/voucher/upload", state: location.state ? location.state : null })}
						>
							<FaFileUpload className="me-2" />
							Upload Voucher Batch
						</button>
					</div>
					<p>
						<small>
							<strong>Please upload the codes in csv format, you can download the template here</strong>
						</small>
					</p>

					<ul className="mt-5 list-group">
						<li className="list-group-item">
							Voucher ID: <strong>{location.state && location.state.data ? location.state.data.itemId : ""}</strong>
						</li>

						<li className="list-group-item">
							Voucher Name: <strong>{location.state && location.state.data ? location.state.data.name : ""}</strong>
						</li>

						<li className="list-group-item">
							Voucher Value: <strong>RM{location.state && location.state.data ? location.state.data.value.toFixed(2) : ""}</strong>
						</li>

						<li className="list-group-item">
							Points: <strong>{location.state && location.state.data ? location.state.data.points : ""}</strong>
						</li>
					</ul>

					<div className="mt-5">
						<div className="card">
							<div className="card-header">
								<h5 className="card-title">Batch</h5>
							</div>

							<div className="card-body text-center">
								<Loader component={<MDBDataTableV5 data={tableData} striped hover responsive bordered />} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		loading: () => dispatch({ type: IS_LOADING }),
		loaded: () => dispatch({ type: IS_LOADED })
	};
};

export default connect(null, mapDispatchToProps)(BatchDetails);
