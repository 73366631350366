import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Redirect } from "react-router-dom";
// Components
import Header from "../../../component/dashboard/Header";
import ContentHeader from "../../../component/dashboard/ContentHeader";
import Footer from "../../../component/dashboard/Footer";
import Loader from "../../../component/Loader";
// Assets
// import pointstaricon from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star.png";
// import pointStar from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/point-star-2.png";
// import cartIcon from "https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/cart.png";
//Redux
import { connect } from "react-redux";
import { sendSample, clearError } from "../../../redux/actions/userActions";
import { useTranslation } from "react-i18next";

const ConfirmOrder = ({ user, location, sendSample, pageReducer, clearError, history }) => {
	const { t } = useTranslation();
	const [showConfirm, setShowConfirm] = useState(false);
	const [success, setSuccess] = useState(false);

	// useEffect(() => {
	// 	window.Insider.eventManager.dispatch("init-manager:re-initialize");
	// }, [showConfirm, pageReducer.isLoading]);

	const processAddress = (line1, line2, line3, city, state, postcode, country) => {
		let text = "";
		text += `${line1}, `;
		if (line2) text += `${line2}, `;
		if (line3) text += `${line3}, `;
		text += `${city}, `;
		text += `${state}, `;
		text += `${postcode}, `;
		text += `${country}`;
		return text;
	};

	const handleMobileCode = num => {
		if (num && num.length > 8 && num[0].toString() !== "0") {
			return "+";
		} else {
			return "+6";
		}
	};

	const handleOpen = () => setShowConfirm(true);

	const handleConfirm = () => {
		const id = user.id;
		const addressId = location.address.id;
		const itemId = location.item.id;
		const sendThis = { id, addressId, itemId };
		sendSample(sendThis, resId => {
			// window.Insider.track("events", [
			// 	{
			// 		event_name: "free_sample_request",
			// 		// timestamp: new Date().toISOString(),
			// 		event_params: {
			// 			// product_id: location.item.itemId,
			// 			// name: location.item.itemName.en,
			// 			// taxonomy: [location.item.brand, location.item.format],
			// 			size: location.item.size,
			// 			// stock: location.item.remaining,
			// 			// product_image_url: location.item.itemImage.en
			// 			custom: {
			// 				order_number: resId,
			// 				product_name: location.item.itemName.en
			// 			}
			// 		}
			// 	}
			// ]);
			setSuccess(true);
		});
		setShowConfirm(false);
	};

	const handlePostSuccess = () => history.push("/dashboard");

	if (!user || !location.address || !location.item) {
		return <Redirect to="/dashboard" />;
	} else {
		return (
			<div id="sample-confirm">
				<Header />
				<div className="content">
					<ContentHeader />

					<div className="free-sample-content">
						<div className="free-sample-item-container">
							<div className="free-sample-item-image-container">
								<img src={location.item.itemImage[pageReducer.language]} alt="item" className="img-fluid free-sample-item-image" />
							</div>
							<h3 className="dbc-title mt-2">{location.item.itemName[pageReducer.language]}</h3>
						</div>

						<div className="free-sample-address-container">
							<div>
								<h3 style={{ color: "#223C96" }}>{t("delivaddr")}</h3>

								<div>
									<p className="m-0 p-0 fw-bold">{user.name}</p>
									<p className="m-0 p-0">
										{handleMobileCode(user.number)}
										{user.number}
									</p>
									<p className="m-0 p-0 mt-1" style={{ lineHeight: "1em" }}>
										{processAddress(
											location.address.line1,
											location.address.line2,
											location.address.line3,
											location.address.city,
											location.address.state,
											location.address.postcode,
											location.address.country
										)}
									</p>
								</div>
							</div>

							<div className="text-center my-5 px-5 button-container">
								<Loader
									component={
										<button
											type="button"
											className="my-5 btn fw-bold"
											style={{ color: "white", backgroundColor: "#FEA30B", width: "100%", maxWidth: "400px" }}
											onClick={handleOpen}
										>
											{t("getfree")}
										</button>
									}
								/>
							</div>
						</div>
					</div>

					<div className="text-center my-5 px-5 desktop-only">
						<Loader
							component={
								<button
									type="button"
									className="my-5 btn fw-bold"
									style={{ color: "white", backgroundColor: "#FEA30B", width: "100%", maxWidth: "400px" }}
									onClick={handleOpen}
								>
									{t("getfree")}
								</button>
							}
						/>
					</div>
					<div className="desktop-only">
						<Footer />
					</div>
				</div>

				<Modal centered show={showConfirm} onHide={() => setShowConfirm(false)}>
					<Modal.Body>
						<div className="text-center">
							<img style={{ width: "40%" }} alt="Cart_icon" src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-free-sample.png"></img>
							<h6 className="addToCartTitle">{t("confirmorder")}</h6>
							<p className="pickSampleModalText">{t("areyouorder")}</p>

							<Loader
								component={
									<div className="text-center">
										<Button
											style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }}
											onClick={() => setShowConfirm(false)}
										>
											{t("cancel")}
										</Button>
										<Button style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }} onClick={handleConfirm}>
											{t("confirm")}
										</Button>
									</div>
								}
							/>
						</div>
					</Modal.Body>
				</Modal>

				<Modal show={pageReducer.error} onHide={() => clearError()} centered>
					<Modal.Body className="text-center">
						<div>
							<p className="addToCartTitle">{t("error")}</p>
							<p className="mt-3 pickSampleModalText">{pageReducer.error}</p>
						</div>

						<div>
							<button className="pickAddressConfirmButton" onClick={() => clearError()}>
								OKAY
							</button>
						</div>
					</Modal.Body>
				</Modal>

				<Modal centered show={success} onHide={handlePostSuccess}>
					<Modal.Body>
						<div className="text-center">
							<img style={{ width: "40%" }} alt="Cart_icon" src="https://dwzg9hxy3ldn9.cloudfront.net/dbc/icons/dash-free-sample.png"></img>
							<h6 className="addToCartTitle">{t("requestsent")}</h6>
							<p className="pickSampleModalText">
								{t("yoursent")}
								<br />
								{t("receivesample")}
							</p>

							<div className="text-center">
								<Button
									style={{ backgroundColor: "#FEA30B", width: "100%", marginTop: "0.5rem" }}
									onClick={handlePostSuccess}
									id="free-sample-ok"
								>
									OKAY
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
};

const mapStateToProps = state => {
	return {
		user: state.userReducer.user,
		pageReducer: state.pageReducer
	};
};

const mapDispatchToProps = dispatch => {
	return {
		sendSample: (data, callback) => dispatch(sendSample(data, callback)),
		clearError: () => dispatch(clearError())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOrder);
